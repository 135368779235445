import axios from "axios";
import { useEffect, useState } from "react"
import { IConnection } from "../dashboard/lists/connections";
import { ISubscription } from "@wideassessment/waui";

export interface ICompany {
    id: number;
    name: string;
    logo: string;
    currentSubscription: ISubscription
    connections: IConnection[];
}

const useCompanyData = (id: number) => {
    const [data, setData] = useState<ICompany>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<any>()

    const fetchData = () => {
        axios.get(`${process.env.BACKEND_URL}/api/salad/company/${id}`).then(res => {
            setData(res.data)
        }).finally(() => {
            setLoading(false)
        }).catch(err => {
            setError(err)
        })
    }

    useEffect(() => {
        if (data?.id === id) return;
        setLoading(true)
        fetchData()
    }, [id])

    return { data, loading, error, setData, refresh: fetchData }
}

export default useCompanyData