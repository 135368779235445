/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import q from 'qjuul'
import BlogPostListItem from './listitem';

import { BlogPost } from '../blogpage';

const cssBlogContainer = css`
    display:grid;
    grid-template-columns: 1fr;
    box-sizing:border-box;
    gap: 40px;
    border-radius: 15px;
`
interface IOwnProps {
    posts: BlogPost[],
    onSwitchPostState: Function
    onEditPost: Function
    selectedPost: BlogPost
}

export default function Blog({ posts, selectedPost, onSwitchPostState, onEditPost }: IOwnProps) {
    if (!posts) return <p>Loading skeleton</p>

    return (
        <q.div css={cssBlogContainer} w100 f1>
            {posts.map(p => <BlogPostListItem key={p.id} highlight={p.id === selectedPost?.id} onEditPost={onEditPost} onSwitchPostState={onSwitchPostState} p={p} />)}
        </q.div>
    )
}
