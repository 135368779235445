
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
// tslint:disable-next-line:no-unused-expression
jsx
import * as React from 'react'
import q from 'qjuul'
import {ZIndex, styles} from '@wideassessment/waui'
import { connectedComponentHelper } from '../typedconnect'
import {Button, Icons} from '@wideassessment/waui'
import ResizeDetector from 'react-resize-detector'

const { propsGeneric, connect } = connectedComponentHelper()(x=> ({
    drawer: x.drawer
}), (_, x) => ({
    openDrawer: x.drawer.openDrawer,
    closeDrawer: x.drawer.closeDrawer,
    setSize: x.drawer.setSize    
}))

class DrawerContainer extends React.PureComponent<typeof propsGeneric>{
    cssContainer = css`
        border-radius: 20px 0 0 20px;
        right: 0px; 
        top: 0;
        min-height:400px;
        height:100vh;
        z-index: ${ZIndex.modal};
        position: fixed;
        min-width: 600px;
        max-width: 1200px;
        background-color: ${styles.color.white};
        overflow: hidden;
        flex-grow: 1;
    `
    renderExitButton = () => {
        const exitHandler = () => {            
            this.props.closeDrawer()
        }
        return (
            <q.div po="absolute" top="15px" ri="15px" z="1001">                
                <Button.Icon borderColor={'transparent'} color={styles.color.white } handleClick={exitHandler}>
                    <Icons.IconReject size="20px" />
                </Button.Icon>
            </q.div>
        )
    }
    
    render(){
        const cssShadow = css`
        box-shadow: 2.5px 6px 10px ${styles.color.companyDark};
    `
        const cssAnimation = css`
        ${this.props.drawer.open ? 
        `transform: translateX(0);
        ` : `
         transform: translateX(${this.props.drawer.size.width !== 0 ? this.props.drawer.size.width : 1000}px);
        `}
        transition: all .2s ease-in-out;
    `

        return(
            <q.div css={[this.cssContainer, cssShadow, cssAnimation]} w100 fctc hidden={!this.props.drawer.open} >
                <q.div w100 baCo={styles.color.white} h100 f1 >
                    {this.renderExitButton()}
                    <ResizeDetector handleHeight handleWidth onResize={(width, height) => this.props.setSize(width, height)} />
                    <q.div w100 ovAu h100>
                        {this.props.drawer.content}                        
                    </q.div>
                </q.div>
            </q.div>
        )
    }
}

export default connect(DrawerContainer)