import * as React from 'react'
import {Ninyo} from '@wideassessment/waui'
import {Button, IButtonTheme } from '@wideassessment/waui'
import color from '../../../styles/color'
import q from 'qjuul'
interface IOwnProps {
    label?: string
    postLabel?: string
    buttonLabel?: string
    buttonTheme?: IButtonTheme
    handleClick?: () => void
    fullPage?: boolean
    backgroundColor?: string
    labelColor?: string
    logoColor?: string
    margin?: string
    imageUrl?: string
    imageSize?: string
    illustration?: React.ReactNode
    extraButton?: React.ReactNode
}
class ContentEmpty extends React.PureComponent<IOwnProps> {
    static defaultProps: Partial<IOwnProps> = {
        label: 'label',
        buttonLabel: 'label',
        buttonTheme: 'outline',
        imageSize: '250px',
    }
    render() {
        return (
            <q.div fccc f1 w100 ma={this.props.margin}
                baCo={this.props.backgroundColor ? this.props.backgroundColor : color.primaryLight}
                he={this.props.fullPage ? '100vh;' : '100%;'}
            >
                {!this.props.imageUrl && !this.props.illustration && <Ninyo color={this.props.logoColor || color.iron} size={'140px'} />}
                {this.props.imageUrl && <img src={`${this.props.imageUrl}`} height={this.props.imageSize}/>}
                {this.props.illustration && this.props.illustration}
                <q.h2 fccc co={this.props.labelColor ? this.props.labelColor : color.black} ma="20px 0">{this.props.label}</q.h2>
                {this.props.postLabel &&
          <q.h3 co={this.props.labelColor ? this.props.labelColor : color.black} ma="0 20px 20px 20px">{this.props.postLabel}</q.h3>}
                <q.div fccc w100 ma="10px 0 0 0">
                    <q.div fccc wi="100%" ma="0 5px">
                        {this.props.buttonLabel !== 'label' &&
            <Button
                handleClick={this.props.handleClick}
                width={'160px'}
                theme={this.props.buttonTheme ? this.props.buttonTheme : this.props.buttonTheme}
                label={this.props.buttonLabel}
            />
                        }
                    </q.div>
                    <q.div fccc ma="10px 0 0 0">
                        {this.props.extraButton}
                    </q.div>
                </q.div>
            </q.div>
        )
    }
}
export default ContentEmpty