/** @jsx jsx */
import { jsx } from '@emotion/react'
jsx
import * as React from 'react'
import { connectHelper } from '../typedconnect'
import q from 'qjuul'
import { styles, Search } from '@wideassessment/waui'
import * as moment from 'moment';
import CompanyUserList from '../dashboard/lists/companyUserList'
import LogList from '../dashboard/lists/logList'
import CandidateUserList from '../dashboard/lists/candidateList'
import { isNumber, isString } from 'lodash'
import { DashboardState } from 'app/state/dashboard/reducer'
import ListModelLayout from './layout'
const S = styles

interface IOwnProps {
    type: number
    dashboardKey?: keyof DashboardState
    parentId?: number | string
}

const TITLE = ['', 'Companies who viewed ', 'Candidates viewed by ']

const { propsGeneric, connect } = connectHelper<IOwnProps>()(x => ({
    dashboard: x.dashboard
}))

const SubList = (props: typeof propsGeneric) => {
    const [search, setSearch] = React.useState('')
    const [searchBounced, setSearchBounced] = React.useState('')

    const { dashboardKey, parentId, type } = props

    const item = (props.dashboard?.[dashboardKey]?.items as any[])?.find?.(x => x.id === parentId)

    const renderRightSide = () => {
        const lastViewed = moment(item?.['lastViewed'], 'YYYY-MM-DD HH:mm:ss')
        return (
            <q.div frcl >
                <q.div fctl pa={'10px 40px 0 0px'} he="100%">
                    <q.p >Last viewed</q.p>
                    <q.p bold="true">{lastViewed.isValid() ? lastViewed.format('D.MMM, YYYY') : 'Never'}</q.p>
                    <q.p bold="true">{lastViewed.isValid() ? lastViewed.format('LT') : ''}</q.p>
                </q.div>
                <q.div baCo={S.color.white} wi="0.5px" he="100%" />
                <q.div fctl pa={'10px 0 0 40px'} he="100%">
                    <q.p >Total views</q.p>
                    <q.p bold="true">#{item?.['totalViews']}</q.p>
                </q.div>
            </q.div>
        )
    }

    const onChangeSearch = (value, debouce = false) => debouce ? setSearch(value) : setSearchBounced(value)

    const picture1 = item?.['picture']
    const picture2 = item?.['logo']
    const theme = props.type === 1 ? 'company' : 'candidate'

    return (
        <ListModelLayout theme={theme} subtitle={`${item?.['email']}`} title={`${item?.name}`} picture={picture1} picture2={picture2} actionBar={renderRightSide()} >
            <q.div>
                <q.p ma=" 0 0 20px 0" foSi="24px" >{TITLE[type] + item?.name}</q.p>
            </q.div>
            <q.div frcl>
                <Search border maxWidth="225px" placeholder='Search' value={search} onChange={(value) => onChangeSearch(value, false)} debounce={500} onChangeBounced={(value) => onChangeSearch(value, true)} />
            </q.div>
            <q.div h100 pa='0 20px 0 0' fctc ovAu>
                <q.div w100 f1>
                    {
                        type === 1 ?
                            <CompanyUserList searchText={searchBounced} list={item?.modalRequest} id={isString(item?.id) && item?.id} /> :
                            <CandidateUserList searchText={searchBounced} list={item?.modalRequest} id={isNumber(item?.id) && item?.id} />
                    }
                </q.div>
            </q.div>
        </ListModelLayout>
    )
}
export default connect(SubList)