/** @jsx jsx */
import { jsx, css } from '@emotion/react'
// tslint:disable-next-line:no-unused-expression
jsx
import * as React from 'react'
import q from 'qjuul'

interface IownProps {
    active: number
    setActive: (id: number) => void
    views: { label: string }[]
}

const SwitchMenu = (props: IownProps) => {
    const renderButtons = () => {
        const cssSwitch = css`
            opacity: 0.3;
            &:not(:first-of-type) {
                padding: 0 0 0 50px;
            }
            font-family:arial;
            &:checked{
                
            }
            &:hover{
                font-weight:bold;
                opacity: 1;
            }
        `
        const cssActive = css`
            text-decoration: underline;
            //text-decoration-color: red;
            opacity: 1;
        `

        return props.views.map((x, i) => {
            const active = props.active === i
            return <q.div pointer onClick={() => props.setActive(i)} css={[cssSwitch, active && cssActive]} key={i}>{x.label}</q.div>
        })
    }

    return (
        <q.div frcl>
            {renderButtons()}
        </q.div>
    )
}

export default (SwitchMenu)