import React from 'react'
import ListModelLayout from './layout'
import { Button, Loading, Markdown } from '@wideassessment/waui'
import q from 'qjuul'
import { connectHelper } from '../typedconnect'
import useCompanyData from '../hooks/useCompanyData'
import { Connections } from '../dashboard/lists/connections'
import TokenMovements from '../dashboard/lists/tokenmovements'

interface IOwnProps {
    companyId: number
}

const { propsGeneric, connect } = connectHelper<IOwnProps>()(x => ({
}), (_, x) => ({
    addPopup: x.popup.addPopup,
}))

const CompanyModel = (props: typeof propsGeneric) => {
    const { data, loading, error, setData, refresh } = useCompanyData(props.companyId)

    const renderError = () => (
        <q.div hide={!error}>
            <Markdown source={JSON.stringify(error, null, 4)} />
        </q.div>
    )

    const renderActions = () => {
        if (!data) return null

        const actions = [

        ]

        return (
            <q.div frcr gap="20px">
                {actions.map(action => (
                    <Button width='200px' key={action.label} label={action.label} handleClick={action.onClick} theme='outlineWhite' height='35px' />
                ))}
            </q.div>
        )
    }

    return (
        <q.div h100 f1 fccc>
            <Loading value={loading} ma="0 0 150px" >
                <ListModelLayout theme='candidate' picture={data?.logo} title={data?.name} actionBar={renderActions()}>
                    {renderError()}
                    <Connections items={data?.connections} refresh={refresh} type='company' />

                    <q.div fctl w100 ma="120px 0">
                        <TokenMovements items={data?.currentSubscription?.tokensOut} refresh={refresh} />
                    </q.div>
                </ListModelLayout>
            </Loading>
        </q.div>
    )
}

export default connect(CompanyModel)