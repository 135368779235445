/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import { Avatar, utils } from '@wideassessment/waui';
import q from 'qjuul'
import { BlogPost } from '../blogpage';
import * as moment from 'moment';
import * as numeral from 'numeral';
import * as pluralize from 'pluralize';

const cssBlogContainer = css`
  font-size: 12px;
  box-sizing: border-box;
`
const cssAuthorInfo = css`
  background-color: #d1dddf;
  border-radius: 15px;
  width:fit-content;
  max-width: 200px;
  padding: 15px 25px;
  p {
    overflow:hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export default function Blog({ post, buttons }: { post: BlogPost, buttons?: JSX.Element }) {

    const renderReadInfo = () => {
        const readTime = moment.duration(post.avgReadTime, 'seconds').asMinutes()
        var text = utils.misc.HtmlToTextOnly(post.body);
        const wordCount = text.split(' ').length
        const estimatedReadTimeMS = Math.round(wordCount * 200)
        const estimatedReadTime = moment.duration(estimatedReadTimeMS, 'ms').asMinutes()

        const isAWord = (x: string) => [...x].every(c => c.toLowerCase().match(/[a-z]/i));

        const hasReaders = post.uniqueReaders > 0
        const hasContent = post.body.length > 5

        const fields = [
            {
                value: post.uniqueReaders,
                prefix: null,
                surfix: 'reader',
                format: '0',
                show: hasReaders
            }, {
                value: estimatedReadTime,
                prefix: 'est',
                surfix: 'min',
                show: hasContent
            }, {
                value: readTime,
                prefix: 'avg',
                surfix: 'min',
                show: hasReaders
            }, {
                value: readTime / estimatedReadTime * 100,
                prefix: 'avg',
                surfix: '%',
                format: '0',
                show: hasReaders && hasContent

            }
        ].filter(x => x.show)

        return (
            <q.div frcl gap='15px' foSi='14px'>
                {fields.map((f, i) => (
                    <q.div frcl gap='3px' key={i} >
                        <q.p hide={!f.prefix}>{f.prefix}</q.p>
                        <q.p bold>{numeral(f.value).format(f.format ?? '0.0')}</q.p>
                        <q.p hide={!f.surfix}>{isAWord(f.surfix) ? pluralize(f.surfix, f.value) : f.surfix}</q.p>
                    </q.div>
                ))}
            </q.div>
        )
    }

    return (
        <q.div fctl gap="15px" css={cssBlogContainer} w100>
            <h1>{post.title}</h1>
            <p>{post.subtitle}</p>
            <q.div frcl gap="15px" w100>
                <q.div css={cssAuthorInfo} frcl gap="10px" pa="10px 0">
                    <Avatar size="30px" imgUrl={post.applicationUser?.picture}></Avatar>
                    <q.p foWe="bold">{post.applicationUser?.firstName} {post.applicationUser?.lastName}</q.p>
                </q.div>
                {renderReadInfo()}
                <q.div frcr w100>
                    {buttons && buttons}
                </q.div>
            </q.div>
        </q.div>
    )
}