import { IColumnOption, ITokenMovement, Icons } from '@wideassessment/waui';
import DynamicList from 'app/components/common/dynamicList'
import { connectHelper } from 'app/components/typedconnect';
import moment from 'moment';
import q from 'qjuul';
import React from 'react'

interface IOwnProps {
    items: ITokenMovement[]
    refresh: () => void
}

const { propsGeneric, connect } = connectHelper<IOwnProps>()(x=> ({
    prices: x.price.prices
}))

const TokenMovements = (props: typeof propsGeneric) => {
    if (!props.items || props.items.length === 0) return null
 
    const connectionColumns = [
        { key: 'id', title: 'Id', sortable: false},
        { key: 'amount', title: 'Amount', flex: 1, formatFunc: (item: ITokenMovement) => <q.div frcc gap="5px"> 
            {item.tokens}
            <Icons.IconToken />
        </q.div> },
        { key: 'action', title: 'Actions', flex: 7, float:'left', sortable: false, formatFunc: (item: ITokenMovement) => props.prices.find(x => x.id === item.action)?.name },
        { key: 'rate', title: 'Rate', flex: 2, sortable: false, formatFunc: (item: ITokenMovement) => `per ${ props.prices.find(x => x.id === item.action)?.recurringType?.toLowerCase().replaceAll('ly', '')}` },
        { key: 'created', title: 'Date', sortable: false, formatFunc: (item: ITokenMovement) => moment.utc(item.date).local().format('DD.MM.YYYY') },
    ] as IColumnOption[]

    const formattedConnectionData = {
        total: props.items?.length,
        start: 0,
        end: props.items?.length,
        sortBy: "created",
        searchText: "",
        decending: false,
        items: props.items
    }

    return (
        <DynamicList data={formattedConnectionData} columns={connectionColumns} defaultSort={'created'} itemsPerPage={10} defaultAscending />
    )
}

export default connect(TokenMovements)