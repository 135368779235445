/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import axios from 'axios';
import q from 'qjuul'
import { useEffect, useState } from 'react';
import BlogPostList from './postlist/blogposts'
import BlogEditor from './editors/editor'
import { Button } from '@mui/material';
import { BlogTypes } from '../../state/types';
export interface BlogPost {
    id: number
    body: string
    created: string
    modified: string
    subtitle: string
    url: string
    thumbnail: string
    isPublished: boolean
    title: string
    thumbnailAlt:string,
    connectGuid:string,
    language: string,
    type: BlogTypes,
    applicationUser?: {
        firstName: string,
        picture: string,
        lastName: string
    },
    uniqueReaders: number
    avgReadTime: number
}

const styles = {
    "&.MuiButton-root": {
        border: "1px solid white",
        fontSize: "10px",
        opacity: "0.7",
        padding: "15px 20px"
    },
    "&.MuiButton-text": {
        color: "white",

    },

    "&.MuiButton-outlined": {
        color: "white"
    }
};

export default function BlogPage() {
    const [posts, setPosts] = useState<BlogPost[]>([])
    const [selectedPost, setSelectedPost] = useState<BlogPost>(null)
    useEffect(() => {
        getAllBlogPosts()
    }, [])

    function getAllBlogPosts() {
        axios.get<BlogPost[]>(`${process.env.BACKEND_URL}` + `/api/blog/posts/all`).then((r) => {
            setPosts(r.data)
        }).catch(e => console.log("err", e))
    }

    function createPost(title: string = "", subtitle: string = "", body: string = "", thumbnail: string = "", url: string = "", thumbnailAlt: string="", language: string ="no", type: BlogTypes=0, conncetGuid: string="") {
        axios.post<BlogPost>(`${process.env.BACKEND_URL}` + `/api/blog/post`, {
            title, subtitle, body, thumbnail, language, type, thumbnailAlt, conncetGuid, url 
        }).then((r) => {
            setSelectedPost(r.data)
            getAllBlogPosts();
        }).catch(e => console.log("err", e))
    }

    function editPost(blogPostData: BlogPost) {
        axios.post(`${process.env.BACKEND_URL}` + `/api/blog/post/${blogPostData.id}`, blogPostData).then((r) => {
            getAllBlogPosts();
            setSelectedPost(null);
        }).catch(e => console.log("err", e))
    }

    function onSwitchPostState(post: BlogPost) {
        axios.post(`${process.env.BACKEND_URL}` + `/api/blog/post/${post.isPublished ? "unpublish" : "publish"}/${post.id}`).then((r) => {
            getAllBlogPosts();
        }).catch(e => console.log("err", e))
    }

    return (
        <q.div frcc >
            {selectedPost ? <BlogEditor allPosts={posts} onClose={() => setSelectedPost(null)} data={selectedPost} onSave={(blogpost) => editPost(blogpost)} /> :
                <q.div>
                    <q.div frtr ma="0 0 20px 0 ">
                        <Button sx={styles} onClick={() => createPost()} variant="outlined" >
              + Create post
                        </Button>
                    </q.div>
                    <BlogPostList selectedPost={selectedPost} onEditPost={(p) => setSelectedPost(p)} onSwitchPostState={onSwitchPostState} posts={posts} />
                </q.div>
            }
        </q.div>
    )
}
