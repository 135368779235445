import * as actions from './actions';
import { ActionType, getType } from 'typesafe-actions';
import React from 'react';
export type PopupActionsType = ActionType<typeof actions>;

export interface Popup {
    id?: string
    element: React.ReactNode,
    position?: 'center-center' | 'bottom-right' | 'top-right' | 'bottom-left' | 'top-left' | 'center-right' | 'center-left' | 'center-top' | 'center-bottom',
    width?: string,
    maxWidth?: string,
    height?: string,
    padding?: string,
    backgroundColor?: string,
    containerPadding?: string,
    textColor?: string,
    noExit?: boolean
    onClose?: () => void,
    exitButton?: boolean
    minHeight?: string
    exitButtonSize?: string
    exitButtonColor?: string
    overlayOpacity?: number
    margin?: string
    maxHeight?: string
    withHistory?: boolean
    zIndex?: number
    caretForExit?: boolean
}


export interface IPopupState {
    activePopup: Popup;
    popupQueue: Popup[];
}

const initialState: IPopupState = {
    activePopup: null,
    popupQueue: [],
};

const popupReducer = (state: IPopupState = initialState, action: PopupActionsType) => {
    let newstate = { ...state };
    switch (action.type) {
        case getType(actions.updatePopup):
            if (!React.isValidElement(action.payload?.element)) return state;
            if (action.payload?.id == newstate.activePopup?.id) newstate.activePopup = action.payload;
            else {
                newstate.popupQueue = newstate.popupQueue.map(p =>
                    p.id == action.payload.id ? action.payload : p
                );
            }
            return newstate;
        case getType(actions.addPopup):
            if (!React.isValidElement(action.payload?.element)) return state;
            var payload = {...action.payload, id: Math.random().toString(36).substring(7)};

            if (action.payload && state.activePopup && payload.element != state.activePopup.element ) {
                return {...state,
                    popupQueue: [...state.popupQueue, payload]
                };
            }
            return { ...state, activePopup: payload };
        case getType(actions.closePopup):
            var nextPopup = [...state.popupQueue].shift() ?? null;
            return { ...state, activePopup: nextPopup, popupQueue: state.popupQueue.filter(x=> x !== nextPopup) };
        case getType(actions.clearPopup):
            return { ...state, activePopup: null, popupQueue: [] };
        default:
            return state;
    }
};

export default popupReducer;