import { createAction } from "typesafe-actions"
import { IApiListRequest, ICandidate, ICompanyUser, ICompany} from "../types"
import { DashboardState } from "./reducer"

export type IMultiplePayload = IApiListRequest<ICandidate> | IApiListRequest<ICompanyUser> | IApiListRequest<ICompany>
type IMultiplePayloadData = { data: IMultiplePayload, key: keyof DashboardState}
type IMultiplePayloadId = { id: number | string, key: keyof DashboardState}
export interface IChildUpdatePayload {
    parentUserId: string | number
    response: IMultiplePayloadData
}

export const set = createAction('SET_ITEMS')<IMultiplePayloadData>()
export const setItemModal = createAction('SET_ITEM_MODAL')<IChildUpdatePayload>()
export const remove = createAction('REMOVE_ITEM')<IMultiplePayloadId>()

export const startTrialSuccess = createAction('SET_COMPANY_TRIAL')<number>()
