import { createAction } from 'typesafe-actions'
export const setCompanies = createAction('SET_COMPANIES')()
export const showCompanyPicker = createAction('SHOW_COMPANY_PICKER')()
export const hideCompanyPicker = createAction('HIDE_COMPANY_PICKER')()
export const showHiringModal = createAction('SHOW_HIRING_MODAL')()
export const hideHiringModal = createAction('HIDE_HIRING_MODAL')()
export const showSocialRecruitingModal = createAction('SHOW_SOCIAL_RECRUITING_MODAL')()
export const hideSocialRecruitingModal = createAction('HIDE_SOCIAL_RECRUITING_MODAL')()
export const showAddCompany = createAction('SHOW_ADD_COMPANY')()
export const hideAddCompany = createAction('HIDE_ADD_COMPANY')()
export const showCompanyMenu = createAction('SHOW_COMPANY_MENU')()
export const hideCompanyMenu = createAction('HIDE_COMPANY_MENU')()
export const hideEditHelp = createAction('HIDE_EDITHELP')()
export const showEditHelp = createAction('SHOW_EDITHELP')()
export const hideImgHelp = createAction('HIDE_IMGHELP')()
export const showImgHelp = createAction('SHOW_IMGHELP')()
export const showLoader = createAction('SHOW_LOADER')()
export const hideLoader = createAction('HIDE_LOADER')()
export const showMenu = createAction('SHOW_MENU')()
export const hideMenu = createAction('HIDE_MENU')()
export const showMenuButton = createAction('SHOW_MENU_BUTTON')()
export const hideMenuButton = createAction('HIDE_MENU_BUTTON')()
export const incrementSpinner = createAction('INCREMENT_SPINNER')()
export const decrementSpinner = createAction('DECREMENT_SPINNER')()
export const loginError = createAction('LOGIN_ERROR')()
export const clearLoginError = createAction('LOGIN_CLEAR_ERROR')()
export const login = createAction('UI_LOGIN')<boolean>()
export const logout = createAction('UI_LOGOUT')()

export const setRegistrationErrors = createAction('SET_REGISTRATION_ERRORS' )<string[]>()
export const setExperienceFilter = createAction('SET_FILTER')<string>()
export const setCloudinary = createAction('SET_CLOUDINARY')<string>()