
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
// tslint:disable-next-line:no-unused-expression
jsx
import * as React from 'react'
import q from 'qjuul'
import {styles} from '@wideassessment/waui'
import ResizeDetector from 'react-resize-detector'
import { connectedComponentHelper } from '../components/typedconnect'
import IState from '../state/IState'
import DrawerContainer from './drawer/container'
import CustomPopup from './modal/custom'

function mapStateToProps(state: IState) {
    return {
        drawer: state.drawer
    }
}

export interface IOwnProps {
    header?: any
    footer?: any
    children: React.ReactNode
}

const { propsGeneric, connect } = connectedComponentHelper<IOwnProps>()(mapStateToProps)

interface IOwnState {
    headerHeight: number
    width: number
}

class Layout extends React.PureComponent<typeof propsGeneric, IOwnState>{
    state : IOwnState = {
        headerHeight: 80,
        width: window.innerWidth || 0
    }

    renderHeader = () => {
        return (
            <q.div ma={`0 0 ${this.state.headerHeight + 50}px 0`}>
                <q.div po="fixed" w100 top={`0`} >
                    <ResizeDetector handleHeight handleWidth onResize={(width, height) => this.setState({headerHeight: height})}/>
                    {this.props.header}
                </q.div>
            </q.div>
        ) 
    }

    renderFooter = () => {
        if (this.props.footer) {
            return (
                <q.div w100 ma="15px 0 0 0">{this.props.footer}</q.div>
            ) 
        }
    }

    render(){
        const cssAnimation = css`
        transition: .2s ease-in-out;
    `
        return(
            <React.Fragment>
                <ResizeDetector handleWidth handleHeight onResize={(width, height)=> this.setState({width: width})}/>
                <q.div fctl he="100%" minh="100%" pa="0 0 80px 0" w100 baCo={styles.color.white}>
                    <CustomPopup />
                    <q.div z={`1000`} w100>{this.renderHeader()}</q.div>
                    <q.div f1 w100 css={cssAnimation}>
                        <q.div pa="0 20px">
                            {this.props.children}
                        </q.div>
                    </q.div>
                    <DrawerContainer />    
                    <q.div z={`1000`} w100 hide>{this.renderFooter()}</q.div>
                </q.div>
            </React.Fragment>
        
        )
    }
}

export default connect(Layout)