/** @jsx jsx */
import { jsx } from "@emotion/react";
jsx;
import { connectHelper } from "../../../../typedconnect";
import { Button } from "@wideassessment/waui";
import q from "qjuul";
import { IFeaturedListItem, IUserListItem } from "app/state/types";
import api from "../../../../api";
import useUserData from "app/components/hooks/useUserData";
import * as _copy from "copy-to-clipboard";

interface IOwnProps {
  user: IFeaturedListItem;
}

const copy = (text: string) => {
  _copy(text);
  furball.info("Copied to clipboard");
};

const { propsGeneric, connect } = connectHelper<IOwnProps>()(null, (_, x) => ({
  deleteUser: x.dashboard.deleteUser,
}));

const FeaturedButtonList = (props: typeof propsGeneric) => {

  const onRemoveFeatured = async () => {
    
    const result = confirm(
      "Are you sure you want to remove " +
        props.user.firstName +
        "as a featured candidate?"
    );
    if (result) {
      api.user.toggleFeatured(props.user.email);
      return;
    }
  };

  return (
    <q.div frcr w100>
        <q.div frbl>
          <Button
            label="Remove Featured"
            minWidth="0px"
            handleClick={onRemoveFeatured}
            theme={"text"}
          />
          <Button
            label="Copy link"
            minWidth="0px"
            handleClick={() => copy(props.user.email)}
            theme={"text"}
          />
        </q.div>
    </q.div>
  );
};

export default connect(FeaturedButtonList);
