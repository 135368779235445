/** @jsx jsx */
import { jsx } from '@emotion/react'
jsx
import * as React from 'react'
import q from 'qjuul'
import { Avatar } from '@wideassessment/waui'
import { styles } from '@wideassessment/waui'
const S = styles

interface IOwnProps {
    picture: string
    picture2?: string
    title: string
    subtitle?: string
    actionBar?: React.ReactNode
    theme: 'candidate' | 'company'
    children: any
}

const ListModelLayout = (props: IOwnProps) => {
    const renderAvatars = () => {
        const width = props.picture2 ? '150px' : '100px'
        return (
            <q.div frcl wi={width}>
                <q.div wi="50px">
                    <q.div z1 wi="100px">
                        <Avatar size="100px" imgUrl={props.picture} />
                    </q.div>
                </q.div>
                <q.div hide={!props.picture2} wi="100px">
                    <Avatar size="100px" imgUrl={props.picture2} />
                </q.div>
            </q.div>)
    }

    const renderTitle = () => {
        return (
            <q.div pa={'10px 0px 0 10px'} fctl >
                <q.p bold="true">{props.title}</q.p>
                <q.p hide={!props.subtitle} >{props.subtitle}</q.p>
            </q.div>
        )
    }


    const renderHeader = () => {
        const headerColor = props.theme === 'candidate' ? S.color.fuchsiaBlue : S.color.turquoise
        return (
            <q.div frcl he="180px" fsb baCo={headerColor} w100 co={S.color.white}>
                <q.div he="100%" frcl pa="0 0 0 20px">
                    {renderAvatars()}
                    {renderTitle()}
                </q.div>
                <q.div he="100%" frcr pa="0 80px 0 0px">
                    {props.actionBar}
                </q.div>

            </q.div>
        )
    }

    return (
        <q.div h100 w100 >
            {renderHeader()}
            <q.div fctl f1 w100>
                <q.div h100 w100 >
                    <q.div pa="20px" >
                        {props.children}
                    </q.div>
                </q.div>
            </q.div>
        </q.div>
    )
}
export default ListModelLayout