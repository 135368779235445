/** @jsx jsx */
import { jsx, css } from '@emotion/react'
jsx
import * as React from 'react'
import { connectHelper } from '../../typedconnect'
import SubList from '../../listmodels'
import { IApiListRequest } from '../../../state/types'
import { ITableOptions, Table, IColumnOption, ITableItem } from '@wideassessment/waui'
import { DashboardState } from 'app/state/dashboard/reducer'
import { useEffect, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import q from 'qjuul'

interface IOwnProps {
    searchText?: string
    sortClick?: (sort: string) => void
    typeId?: number
    dashboardKey?: keyof DashboardState
    itemsPerPage?: number
    columns?: IColumnOption[]
    onRowClick?: boolean
    data: any,
    defaultSort: string
    defaultAscending?: boolean
    fetchData?:  (start: number, end: number, sortBy: string, decending: boolean, id?: string | number) => Promise<any>
    drawerModel?: (id: string | number) => React.ReactNode
}

const { propsGeneric, connect } = connectHelper<IOwnProps>()(x => ({
    drawer: x.drawer
}), (_, x) => ({
    openDrawer: x.drawer.openDrawer,
    closeDrawer: x.drawer.closeDrawer
}))

const DynamicList = (props: typeof propsGeneric) => {
    const [loading, setLoading] = useState(false)
    const intOptions: ITableOptions = {
        showHeader: true,
        pagination: {
            footerPlacement: 'none',
            headerPlacement: 'none',
            showMorePaginatior: true,
            itemsPerPageOptions: [props.itemsPerPage ?? 999],
            itemsPerPage: props.itemsPerPage ?? 999
        },
        showIndex: false,
        columns: props.columns,
        decending: !props.defaultAscending,
        onRowClick: props.onRowClick ? (x) => rowClickHandler(x) : null,
        expandable: false,
        grid: false,
        padding: '0 15px',
        gridOnHeader: false,
        border: false,
        selectable: false,
        sortBy: props.defaultSort,
        sortable: true,
        position: 'left',
        expandElement: null,
        selectedItems: [],
        serverSide: true
    }

    const [tableOptions, setTableOptions] = useState(intOptions)

    const rowClickHandler = (row: any) => {
        const id = row.id
        if (props.drawer.contentId === id) {
            props.drawer.open ? props.closeDrawer() :
                props.openDrawer(props.drawer.content, id, props.drawer.contentType)
        } else {
            if (props.typeId) {
                let Content = props.drawerModel?.(id) ?? <SubList dashboardKey={props.dashboardKey} parentId={row.id} key={id} type={props.typeId} />
                props.openDrawer(Content, id, props.typeId)
            }
        }
    }

    const FetchData = (start) => {
        if (!props.fetchData) return;
        setLoading(true)
        props.fetchData(start, start + tableOptions.pagination.itemsPerPage, tableOptions.sortBy, tableOptions.decending).finally(() => {
            setLoading(false)
        })
    }

    const checkAndFetch = () => {
        if (!props.fetchData) return;
        const el: IApiListRequest<any> = props.data
        const opt = tableOptions
        const p = props

        if (!el || (el.searchText ? el.searchText : '') !== p.searchText || el.decending !== opt.decending || el.sortBy != opt.sortBy || el.end - el.start !== opt.pagination?.itemsPerPage) {
            FetchData(0)
        }
    }

    useEffect(() => {
        checkAndFetch();
    }, [])

    const getPageNr = (el: IApiListRequest<any>) => {
        if (el) {
            const start = el.start
            const perPage = tableOptions.pagination.itemsPerPage
            return Math.ceil((start) / perPage)
        }
        else return 0
    }

    const sortbyHandler = (column, selected) => {
        var opt = { ...tableOptions }
        if (selected) opt.decending = !opt.decending
        else opt.sortBy = column
        setTableOptions(opt)
    }

    const pageHandler = (page: number) => FetchData(page * tableOptions.pagination.itemsPerPage)

    const pageSizeHandler = (size: number) => {
        var opt = { ...tableOptions }
        opt.pagination.itemsPerPage = size
        setTableOptions(opt)
    }

    useUpdateEffect(() => {
        checkAndFetch();
    }, [tableOptions])

    useUpdateEffect(() => {
        checkAndFetch();
    }, [props.searchText])
    

    var ls = props.data?.items ?? []
    const data = {
        items: [...ls.map((x, i) => ({ id: i, content: x }) as ITableItem)],
        page: getPageNr(props.data),
        total: props.data?.total ? props.data?.total : 0
    }

    return (
        <React.Fragment>
            <q.div po="relative" w100>
                <q.div co="grey" foSi="12px" po="absolute" ri="0px">{!(props.itemsPerPage && !props.fetchData) ? `${data.items.length}/` : ''}{props.data?.total}</q.div>
                <Table data={data} options={{...tableOptions, loading}} setSortBy={sortbyHandler} setPageSize={pageSizeHandler} setPage={pageHandler} />
            </q.div>
        </React.Fragment>
    )

}

export default connect(DynamicList)