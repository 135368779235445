import * as React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import IState, { AllTCombinedCreators, RemoveDispatchType, TCombinedCreators } from 'app/state/IState'

type Func<T, A, B> = (arg: A, arg2?: B) => T
type TypeOfSameKeys<T, U> = Pick<T, { [K in keyof T]: K extends keyof U ? K : never }[keyof T]>

//Array support can be added, but it needs to be converted to { key: string, value: function } first
//Typescript can't differentiate between a function based on name, so we need to use the key to find the correct function

export const connectedComponentHelper = <TProps = {}>() => {
    const stateFile: any = require('../state/IState')

    return <TStateProps, TDispatchProps>(
        mapStateToProps: Func<TStateProps, IState | Partial<IState> | undefined | null, undefined>,
        mapDispatchToProps?: Func<TDispatchProps | AllTCombinedCreators, Dispatch | null, TCombinedCreators>
    ) => {
        type MatchingKeyFunctions = TypeOfSameKeys<AllTCombinedCreators, TDispatchProps>
        return (
            {
                connect: (component) =>
                    connect(
                        mapStateToProps,
                        (dispatch) => {
                            const combindedCreators : TCombinedCreators = stateFile.combindedCreators 
                            const combinedFunctions = Object.keys(combindedCreators).reduce((acc, key) => ({...acc, ...combindedCreators[key]}), {})

                            const returnObject = typeof mapDispatchToProps === 'function' ? mapDispatchToProps(dispatch, stateFile.combindedCreators ?? {}) : {}
                            let clone = {...returnObject}
                            Object.keys(returnObject).forEach(key => {
                                const functionMatch = combinedFunctions[key]                            
                                if (typeof returnObject[key] === 'function' && functionMatch) {
                                    if (functionMatch === returnObject[key]) {
                                        clone[key] = (...args) => returnObject[key](dispatch, ...args)
                                    }                                
                                }
                            })
                            return clone
                        }
                    )
                    (component) as any as React.ComponentClass<TProps>,
                propsGeneric: null as TProps & TStateProps & (
                    MatchingKeyFunctions & RemoveDispatchType<TDispatchProps> // Type for creators without dispatch
                )
            }
        )
    }
}

export const connectHelper = connectedComponentHelper