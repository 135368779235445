import React from 'react'
import ListModelLayout from './layout'
import useUserData from '../hooks/useUserData'
import { Button, Loading, Markdown } from '@wideassessment/waui'
import q from 'qjuul'
import api from '../api'
import { connectHelper } from '../typedconnect'
import ConnectUserModal from '../modal/views/user/connectuser'
import ChangePasswordModal from '../modal/views/user/changepassword'
import LogList from '../dashboard/lists/logList'
import { Connections } from '../dashboard/lists/connections'

interface IOwnProps {
    userId: string
}

const { propsGeneric, connect } = connectHelper<IOwnProps>()(x => ({
}), (_, x) => ({
    addPopup: x.popup.addPopup,
}))

const UserModel = (props: typeof propsGeneric) => {
    const { data, loading, error, setData, refresh } = useUserData(props.userId)

    const renderError = () => (
        <q.div hide={!error}>
            <Markdown source={JSON.stringify(error, null, 4)} />
        </q.div>
    )

    const renderActions = () => {
        if (!data) return null

        const _toggleFeatured = () => {
            api.user.toggleFeatured(data.username)
            setData({ ...data, isFeatured: !data.isFeatured })
        }

        const actions = [
            data.isFeatured ?
                { label: 'Remove featured', onClick: _toggleFeatured } :
                { label: 'Set as featured', onClick: _toggleFeatured },            
            { label: 'Change password', onClick: () => props.addPopup(<ChangePasswordModal {...{refresh}} user={data} />)},
            { label: 'Connect', onClick: () => props.addPopup(<ConnectUserModal {...{refresh}} user={data} />) },
        ]

        return (
            <q.div frcr gap="20px">
                {actions.map(action => (
                    <Button width='200px' key={action.label} label={action.label} handleClick={action.onClick} theme='outlineWhite' height='35px' />
                ))}
            </q.div>
        )
    }

    return (
        <q.div h100 f1 fccc>
            <Loading value={loading} ma="0 0 150px" >
                <ListModelLayout theme='candidate' picture={data?.picture} title={`${data?.isFeatured ? "🔥 " : ""}${data?.name}`} subtitle={data?.username} actionBar={renderActions()}>
                    {renderError()}
                    <Connections items={data?.connections} refresh={refresh} type='user' />
                    <q.div fctl w100 ma="120px 0">
                        <LogList id={props.userId} />
                    </q.div>
                </ListModelLayout>
            </Loading>
        </q.div>
    )
}

export default connect(UserModel)