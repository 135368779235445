enum color {
    // COMMON
    white = '#fff',
    black = '#333',
    grey = '#333',
    // PRIMARY
    violentViolet = '#240F67',
    fuchsiaBlue = '#8645C0',
    aliceBlue = '#FAFDFF',
    mineShaft = '#333',
    haiti = '#1B0F40',
    // SECONDARY
    turquoise = '#3CCCCC',
    blueDianne = '#235353',
    blackSqueeze = '#F0F6FA',
    waterLeaf = '#96E6E6',
    persianGreen = '#00A3A3',
    heliotrope = '#B869FF',
    // COMPANY
    oxfordBlue = '#394959',
    sanMarino = '#4267B2',
    royalBlue = '#3578E5',
    athensGrey = '#E9EBEE',
    regentGrey = '#7D95A0',
    ebonyClay = '#2B3742',
    darkEbonyClay = '#263039',
    companyDark = ebonyClay,
    companyDarker = darkEbonyClay,
    companyMedium = sanMarino,
    companyNormal = royalBlue,
    companyLight = athensGrey,
    companyGrey = regentGrey,
    // ADDITIONAL
    shamrock = '#2ECC71',
    radicalRed = '#FC3A5A',
    seaBuckthorn = '#FDA629',
    brinkPink = '#FD657E',
    gallery = '#EEE',
    porcelain = '#F4F5F6',
    riverBed = '#404A54',
    viking = '#56D5D5',
    minsk = '#382776',
    silverSand = '#C8CACC',
    iron = '#CDCFD0',
    jungleGreen = '#2AB164',
    eucalyptus = '#2B9457',
    broom = '#FFEA24',
    shuttleGrey = '#5F6E7D',
    candyCorn = '#FAE756',
    /* COLORS BY FUNCTION */
    // PRIMARY
    primary = fuchsiaBlue,
    primaryDark = violentViolet,
    primaryLight = aliceBlue,
    primaryLighter = heliotrope,
    // SECONDARY
    secondary = waterLeaf,
    secondaryLight = blackSqueeze,
    secondaryMedium = turquoise,
    secondaryDark = persianGreen,
    // ADDITIONALS
    go = shamrock,
    goDark = jungleGreen,
    stop = brinkPink,
    stopOnDark = radicalRed,
    wait = seaBuckthorn,
    save = eucalyptus,
    // EXPERIENCE COLORS
    education = companyMedium,
    work = turquoise,
    project = seaBuckthorn,
}

export default color