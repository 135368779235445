import axios from 'axios'
axios.defaults.withCredentials = true
import * as a from './actions'
import { Dispatch } from 'redux'

export const FetchFilters = (dispatch: Dispatch, pageStart: number, pageEnd: number, sortBy: string, searchText: string, reverse: boolean, id? : string | number, isCandidate?: boolean,) => {
    let url = `${process.env.BACKEND_URL}/api/admin/filter?pageStart=${pageStart}&pageEnd=${pageEnd}&sortBy=${sortBy}${searchText && searchText !== '' ? `&searchText=${searchText}`: ''}&reverse=${reverse}`
    return axios.get(url).then(x=> {
        const data = {...x.data, decending:reverse, sortBy: sortBy ? sortBy : null}
        dispatch(a.setFilters(data))
    }).catch(err => {
        console.log(err);
    })
}

