import axios from "axios";
import { useEffect, useState } from "react"
import { IConnection } from "../dashboard/lists/connections";

export interface IUser {
    id: string;
    publicShareCode: string;
    name: string;
    username: string;
    picture: string;
    isFeatured: boolean;
    connections: IConnection[];
}

const useUserData = (userId: string) => {
    const [data, setData] = useState<IUser>()
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<any>()

    const fetchData = () => {
        axios.get(`${process.env.BACKEND_URL}/api/salad/users/${userId}`).then(res => {
            setData(res.data)
        }).finally(() => {
            setLoading(false)
        }).catch(err => {
            setError(err)
        })
    }

    useEffect(() => {
        if (data?.id === userId) return;
        setLoading(true)
        fetchData()
    }, [userId])

    return { data, loading, error, setData, refresh: fetchData }
}

export default useUserData