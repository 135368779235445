/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import {Button} from '@mui/material';
import q from 'qjuul'
import { BlogPost } from '../blogpage';

const cssBlogContainer = css`
    box-sizing: border-box;
    display: flex;
    gap: 15px;
    transform: opacity 500ms ease-in;

`
interface IOwnProps {
    post: BlogPost
    onSwitchPostState?: Function
    onEditPost?: Function
    hide?: boolean
}
export default function PostControlPanel(props: IOwnProps) {
    const { post, onSwitchPostState, onEditPost, hide } = props
    return (
        <q.div css={cssBlogContainer} notvisible={hide} >  
            <Button onClick={() => onSwitchPostState(post)} variant="contained" color={post.isPublished ? "error" : "success"} >
                {post.isPublished ? "Unpublish" : "Publish"}
            </Button>
            <Button onClick={() => onEditPost(post)} >
            Edit post
            </Button>
        </q.div>
    )
}