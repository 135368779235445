import * as React from 'react'
import { connectedComponentHelper } from '../../components/typedconnect'
import CircularProgress from '@mui/material/CircularProgress';
import q from 'qjuul';
import { Button } from '@wideassessment/waui';
interface IOwnProps {
    children: React.ReactNode
}

const { propsGeneric, connect } = connectedComponentHelper<IOwnProps>()(x=> ({
    loggedIn: x.ui.loggedIn,
    profile: x.profile
}), (dispatch, x ) => ({
    checkIfLoggedIn: x.ui.checkIfLoggedIn,
    Logout: x.profile.logout
}));

class LoginChecker extends React.Component<typeof propsGeneric> {
    componentDidMount = () => {
        if (!this.props.loggedIn) this.props.checkIfLoggedIn();
    }

    logout = () => {
        this.props.Logout().then(x => {
            window.location.href = `${process.env.BACKEND_URL}/login?goto=${location.href}`
        })
    }

    render() {
        if (!this.props.loggedIn) return (
            <q.div f1 h100 w100 fccc>
                <CircularProgress />
            </q.div>
        )

        if (!this.props.profile?.isAdmin) return (
            <q.div f1 h100 w100 txal='center' fccc>
                <q.h1>You are not an admin</q.h1>
                <Button label='Login as another user' padding='0px 50px' handleClick={this.logout} />
            </q.div>
        )
        if (this.props.loggedIn) return this.props.children
    }
}
export default connect(LoginChecker)