/** @jsx jsx */
import { jsx } from '@emotion/react'
jsx
import * as React from 'react'
import DateFormat from 'app/components/common/dateFormat'
import DynamicList from 'app/components/common/dynamicList'
import { sortDate } from 'app/utils/dateHelper'
import { Avatar, Ellipsis, IColumnOption } from '@wideassessment/waui'
import q from 'qjuul'
import { connectHelper } from 'app/components/typedconnect'
import { IUserListItem } from 'app/state/types'
import UserButtonList from './buttons/userbuttonlist'
import * as _copy from 'copy-to-clipboard'
import _ from 'lodash'
import UserModel from 'app/components/listmodels/user'

interface IOwnProps {
    searchText: string
}

const copy = (text: string) => {
    _copy(text)
    furball.info('Copied to clipboard')
}

const COLS = (closeDrawer) => [    
    { key: 'name', title: 'Name', flex: 2, sortable: true, formatFunc: (item: IUserListItem) => <q.div gap="10px" frcc pa="0 15px"><Avatar size="35px" imgUrl={item.picture}/><Ellipsis tooltip={item.name}>{item.isFeatured ? "🔥" : ""}{item.name}</Ellipsis></q.div>,},
    { key: "email", title: "Email", sortable: true, flex: 2, formatFunc: (item) => <q.div onClick={_=> {
        copy(item.email)
        setTimeout(() => {
            closeDrawer()
        }, 1);
    }} pointer>{item.email}</q.div>},
    { key: 'created', title: 'Registration', width: '220px', formatFunc: (item: IUserListItem) => (<DateFormat item={item.created}/>), sortable: true, sortFunc: (a, b) => sortDate(a, b, 'created')},
    { key: "quality", title: "Quality", sortable: true },
    { key: "source", title: "Source", sortable: true },
    { key: "county", title: "County", sortable: true },
    { key: "country", title: "Country", sortable: true },    
    { key: 'publicShareCode', title: 'Id', width: '85px', sortable: true, noClick: true, formatFunc: (item: IUserListItem) => <q.div onClick={_=> {
        copy(item.publicShareCode)
        setTimeout(() => {
            closeDrawer()
        }, 1);
    }} pointer>{item.publicShareCode}</q.div> },
    { key: "actions", noClick: true, title: "Actions", float: 'right', sortable: false, formatFunc: (item) => <UserButtonList user={item}/> },
] as IColumnOption[]

const { connect, propsGeneric } = connectHelper<IOwnProps>()(x=> ({
    users: x.dashboard.users,
}), (_, x) => ({
    FetchUsers: x.dashboard.FetchUsers,
    closeDrawer: x.drawer.closeDrawer,
}))

const UserList = (props: typeof propsGeneric) =>{
    
    const FetchData = (start, itemsPerPage, sortBy, descending ) =>  props.FetchUsers(start, itemsPerPage, sortBy, props.searchText, descending)

    const renderRowModel = (itemId: string) => <UserModel userId={itemId} />
    return(
        <DynamicList onRowClick drawerModel={renderRowModel} data={props.users} fetchData={FetchData} itemsPerPage={10} columns={COLS(props.closeDrawer)} defaultSort={'created'} typeId={2} searchText={props.searchText}/>
    )   

}

export default connect(UserList)