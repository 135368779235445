import { getType, ActionType } from 'typesafe-actions'
import * as actions from './actions'
export type ProfileActionsType = ActionType<typeof actions>
export enum PokeSettings {
    ManualPokeHandling,
    AutoAcceptPokes,
    NoPokes,
}
export interface IOwnProfile{
    username: string
    firstName: string
    lastName: string
    picture: string
    phone: string
    contactEmail: string
    isAdmin?: boolean
}

export const profileInitialState: IOwnProfile = {
    contactEmail: '',
    username: '',
    firstName: '',
    lastName: '',
    picture: '',
    phone:'',
}

const profile = (state = profileInitialState, action: ProfileActionsType) => {
    switch (action.type) {
        case getType(actions.setFromCache):
            return state
        case getType(actions.set):
            const payload = Object.assign({}, action.payload)
            let picUrl = payload.picture
            if (picUrl && picUrl.indexOf('https') === -1) picUrl = picUrl.replace('http', 'https')
            payload.picture = picUrl
            return Object.assign({}, state, payload)
        case getType(actions.userType):
            return (Object as any).assign({}, state, { userType: action.payload as any })
        case getType(actions.firstName):
            return (Object as any).assign({}, state, { firstName: action.payload })
        case getType(actions.lastName):
            return (Object as any).assign({}, state, { lastName: action.payload })
        case getType(actions.picture):
            return (Object as any).assign({}, state, { picture: action.payload })
        case getType(actions.phone):
            return (Object as any).assign({}, state, { phone: action.payload })
        case getType(actions.emails):
            return (Object as any).assign({}, state, { showLinkModal: false })
        default:
            return state
    }
}
export default profile
