/** @jsx jsx */
import { jsx, css } from '@emotion/react'
// tslint:disable-next-line:no-unused-expression
jsx
import * as React from 'react'
import Confirm from '.'
import { styles } from '@wideassessment/waui'

interface IOwnProps {
    onConfirm: () => void
    action?: string
    message?: string | React.ReactNode
    messageIsReact?: boolean
    title?: string
    disabled?: boolean
}

const ConfirmAction = (props: IOwnProps) => {
    return (
        <Confirm
            txtalign='center'
            title={props.title ?? 'Are you sure?'}
            message={props.message || `Are you sure you want to ${props.action}?`}
            messageIsReact={props.messageIsReact}

            buttonLabel1='Confirm'
            buttonTheme1={'go'}
            handleClick1={props.onConfirm}
            buttonDisabled1={props.disabled}

            buttonTheme3={'text'}
            buttonLabel3='Cancel'
            buttonLabelColor3={styles.color.white}
        />
    )
}

export default ConfirmAction