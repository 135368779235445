import { Reducer } from 'redux'
import { getType, ActionType } from 'typesafe-actions'
import * as actions from './actions'
export type UiActionsType = ActionType<typeof actions>

export type UiState = {
    readonly menu: boolean,
    readonly menuButton: boolean,
    readonly loader: boolean,
    readonly experienceFilter: string,
    readonly editHelp: boolean,
    readonly imgHelp: boolean,
    readonly cloudinary: string,
    readonly companyMenu: boolean,
    readonly companyPicker: boolean,
    readonly addCompany: boolean,
    readonly setupDone: boolean,
    readonly spinnerCount: number,
    readonly loginError: boolean,
    readonly loggedIn: boolean
    readonly registrationErrors: string[],
    readonly showHiringModal: boolean,
    readonly showSocialRecruitingModal: boolean,
}
const initialState: UiState = {
    menu: false,
    menuButton: true,
    loader: true,
    experienceFilter: '',
    editHelp: false,
    imgHelp: false,
    cloudinary: '',
    companyMenu: false,
    companyPicker: false,
    addCompany: false,
    setupDone: false,
    spinnerCount: 0,
    loginError: false,
    loggedIn: false,
    registrationErrors: [],
    showHiringModal: false,
    showSocialRecruitingModal: false
}

const reducer: Reducer<UiState> = (state: UiState = initialState, action: UiActionsType) => {
    switch (action.type) {
        case getType(actions.setCompanies):
            return ({ ...state, companyMenu: false, companyPicker: false })
        case getType(actions.showCompanyPicker):
            return ({ ...state, companyPicker: true })
        case getType(actions.hideCompanyPicker):
            return ({ ...state, companyPicker: false })
        case getType(actions.showAddCompany):
            return ({ ...state, addCompany: true })
        case getType(actions.hideAddCompany):
            return ({ ...state, addCompany: false })
        case getType(actions.showCompanyMenu):
            return ({ ...state, companyMenu: true })
        case getType(actions.hideCompanyMenu):
            return ({ ...state, companyMenu: false })
        case getType(actions.setCloudinary):
            return ({ ...state, cloudinary: action.payload })
        case getType(actions.hideEditHelp):
            return ({ ...state, editHelp: false })
        case getType(actions.showEditHelp):
            return ({ ...state, editHelp: true })
        case getType(actions.hideImgHelp):
            return ({ ...state, imgHelp: false })
        case getType(actions.showImgHelp):
            return ({ ...state, imgHelp: true })
        case getType(actions.setExperienceFilter):
            return ({ ...state, experienceFilter: action.payload })
        case getType(actions.showLoader):
            return ({ ...state, loader: true })
        case getType(actions.hideLoader):
            return ({ ...state, loader: false })
        case getType(actions.showMenu):
            return ({ ...state, menu: true })
        case getType(actions.hideMenu):
            return ({ ...state, menu: false })
        case getType(actions.showMenuButton):
            return ({ ...state, menuButton: true })
        case getType(actions.hideMenuButton):
            return ({ ...state, menuButton: false })
        case getType(actions.incrementSpinner):
            return ({ ...state, spinnerCount: state.spinnerCount + 1 })
        case getType(actions.decrementSpinner):
            return ({ ...state, spinnerCount: state.spinnerCount - 1 })
        case getType(actions.loginError):
            return ({ ...state, loginError: true })
        case getType(actions.clearLoginError):
            return ({ ...state, loginError: false })
        case getType(actions.setRegistrationErrors):
            return ({ ...state, registrationErrors: action.payload })
        case getType(actions.login):
            return ({ ...state, loggedIn: action.payload })
        case getType(actions.logout):
            return ({ ...state, loggedIn: false })
        case getType(actions.showHiringModal):
            return ({ ...state, showHiringModal: true })
        case getType(actions.hideHiringModal):
            return ({ ...state, showHiringModal: false })
        case getType(actions.showSocialRecruitingModal):
            return ({ ...state, showSocialRecruitingModal: true })
        case getType(actions.hideSocialRecruitingModal):
            return ({ ...state, showSocialRecruitingModal: false })
        default:
            return state
    }
}
export default reducer
