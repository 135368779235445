/** @jsx jsx */
import { jsx } from '@emotion/react'
jsx
import * as React from 'react'
import { connectedComponentHelper } from '../../typedconnect'
import DateFormat from '../../common/dateFormat'
import DynamicList from '../../common/dynamicList'
import { sortDate } from '../../../utils/dateHelper'
import q from 'qjuul'
import { Avatar, Ellipsis } from '@wideassessment/waui'
import { IApiListRequest, ICompanyUser } from '../../../state/types'

interface IOwnProps {
    id?:string
    searchText: string
    list?:IApiListRequest<ICompanyUser>
}

const { propsGeneric, connect } = connectedComponentHelper<IOwnProps>()(x=> ({
    companyAdmins: x.dashboard.companyUsers,
}), (_, x)=> ({
    FetchActivity: x.dashboard.FetchActivity
}))

class CompanyUserList extends React.PureComponent<typeof propsGeneric>{
    createCols = (isSubList: boolean) => {
        return [ 
            { key: 'companyName', minWidth: '150px', title: 'Company', sortable: true, formatFunc: (item) => <q.div gap="10px" frcc pa="0 15px"><Avatar size="35px" imgUrl={item.logo}/><Ellipsis tooltip={item.companyName}>{item.companyName}</Ellipsis></q.div>,},
            !isSubList && { key: 'name', title: 'User', sortable: true}, 
            !isSubList && { key: 'lastLogin', title: 'Last login', width: '220px', formatFunc: (item) => (<DateFormat item={item.lastLogin} modal/> ), sortable: true, sortFunc: (a, b) => sortDate(a, b, 'lastLogin')},
            { key: 'lastViewed', title: 'Last viewed', width: '220px', formatFunc: (item) => (<DateFormat item={item.lastViewed} modal /> ), sortable: true, sortFunc: (a, b) => sortDate(a, b, 'lastViewed')},
            { key: 'totalViews', title: 'Total views', width: "100px", sortable: true, formatFunc: (item) => `#${item.totalViews}` }
            
        ]
    } 

    fetchData = async (start, itemsPerPage, sortBy, descending ) => {
        await this.props.FetchActivity(start, itemsPerPage, sortBy, this.props.searchText, descending, this.props.id, false)
    }

    render(){
        let data = null
        const isSubList = typeof this.props.id !== 'undefined' && typeof this.props.id != null 
        if (isSubList) data = this.props.list
        else data=this.props.companyAdmins
        return (
            <DynamicList fetchData={this.fetchData} dashboardKey='companyUsers' data={data} itemsPerPage={10} columns={this.createCols(isSubList)} onRowClick={!isSubList} defaultSort={isSubList ? 'lastViewed' : 'lastLogin'} typeId={2} searchText={this.props.searchText}/>
        )   
    }
}

export default connect(CompanyUserList)