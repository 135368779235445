import { createAction } from 'typesafe-actions'
import { IOwnProfile } from './reducer'
export const setFromCache = createAction('SET_PROFILE_FROM_CACHE')<IOwnProfile>()
export const set = createAction('SET_PROFILE')<IOwnProfile>()
export const firstName = createAction('SET_FIRSTNAME')<string>()
export const lastName = createAction('SET_LASTNAME')<string>()
export const picture = createAction('SET_PICTURE')<string>()
export const phone = createAction('SET_PHONE')<string>()
export const userType = createAction('SET_USERTYPE')<string | React.ReactElement<any>>()
export const emails = createAction('SET_EMAILS')<{ emails: ReadonlyArray<string>}>()
export const setContactEmail = createAction('SET_CONTACT_EMAIL')<{email: string}>()