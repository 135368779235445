/** @jsx jsx */
import { jsx, css } from '@emotion/react'
// tslint:disable-next-line:no-unused-expression
jsx
import * as React from 'react'
import q from "qjuul";
import { Button, Checkbox, IButtonTheme, styles, utils } from '@wideassessment/waui'
import ModalContainer from '../modalcontainer'
import { useEffect, useRef, useState } from 'react';
import { connectHelper } from '../../../typedconnect';

interface IOwnProps {
    title?: string
    message?: string | React.ReactNode
    messageIsReact?: boolean
    buttonLabel1?: string
    buttonLabel2?: string
    buttonLabel3?: string
    buttonLabelColor1?: string
    buttonLabelColor2?: string
    buttonLabelColor3?: string
    buttonTheme1?: IButtonTheme
    buttonTheme2?: IButtonTheme
    buttonTheme3?: IButtonTheme
    handleClick1?: () => void
    handleClick2?: () => void
    handleClick3?: () => void
    buttonDisabled1?: boolean
    popupKey?: string
    hideCancel?: boolean
    popupName?: string
    contentMinHeight?: string
    maxWidth?: string
    onExit?: () => void
    checkboxLabel?: string
    txtalign?: 'left' | 'center' | 'right'
    dontExitOnConfirm?: boolean
}

const { connect, propsGeneric} = connectHelper<IOwnProps>()(x=> ({
}), (dispatch, x) => ({
    removePopup: () => x.popup.removePopup(dispatch),
}))

const ConfirmModal = (props: typeof propsGeneric) => {
    const { title = 'Are you sure?', message, contentMinHeight = '60px', maxWidth = '450px' } = props
    const [popupChecked, setPopupChecked] = useState(false)

    const dontRunOnExit = useRef(false)

    const onExit = () => {        
        if (!dontRunOnExit.current) props.onExit && props.onExit()
        if (dontRunOnExit.current) dontRunOnExit.current = false
    }

    const handleClick = (i) => {
        props[`handleClick${i}`] && props[`handleClick${i}`]()
        props.removePopup()
        if (props.dontExitOnConfirm && i === 1) dontRunOnExit.current = true
        onExit()
        
    }

    useEffect(() => {
        const handleKeydown = (e) => {
            if (e.key.toLowerCase() === 'enter') {
                handleClick(1)
            }
        }
        window.addEventListener('keydown', handleKeydown)
        return () => {
            window.removeEventListener('keydown', handleKeydown)
        }
    }, [])

    const renderButtons = (i) => {
        if (!props[`buttonLabel${i}`] && (i !== 3 || props.hideCancel)) return null
        const defaultLabel = i === 1 ? 'Yes' : i === 2 ? 'No' : 'Cancel'
        const defaultTheme = i === 1 ? 'go' : i === 2 ? 'stop' : 'text'
        const disabled = i===1 && props.buttonDisabled1
        return (
            <Button
                label={props[`buttonLabel${i}`] ?? defaultLabel}
                disabled={disabled}
                theme={props[`buttonTheme${i}`] ?? defaultTheme}
                handleClick={() => handleClick(i)}
                labelColor={props[`buttonLabelColor${i}`] ?? i === 3 ? styles.color.white : undefined}
                style={'small'}
            />
        )
    }

    enum CheckBoxTheme {
        light = 'light',
        default = 'default',
    }

    const { txtalign = 'left' } = props
    return (
        <ModalContainer exitButton maxWidth={maxWidth} width='100%' padding="40px 30px" exitButtonSize='10px' onClose={onExit} exitButtonColor={styles.color.white}>
            <q.div fccc w100 txal='center' h100 gap='20px'>
                <q.p foSi="16px" fcbc maxw='160px' foWe='600' foFam='bree'>{title}</q.p>
                <q.div fccc f1 w100 maxw="300px">
                    <q.div frtl minh={contentMinHeight} fctl fsb w100 foSi="12px !important" >
                        {props.messageIsReact ? <q.div w100 txal={txtalign} foFam='ubuntu' >{message}</q.div> :
                            <q.p5 txal={txtalign} foFam='ubuntu' w100 dangerouslySetInnerHTML={({ __html: utils.misc.RemoveDangerousTags(message)})}/>}
                        <q.div minh='20px' frtl>
                            {
                                props.popupKey &&
                                <Checkbox
                                    iconSize='16px'
                                    theme={CheckBoxTheme.light}
                                    fontSize='12px'
                                    italic
                                    color={styles.color.white}
                                    label={props.checkboxLabel ? props.checkboxLabel :`Don't ask to confirm ${props.popupName} again`}
                                    checked={popupChecked}
                                    onClick={()=>setPopupChecked(!popupChecked)}
                                />
                            }
                        </q.div>
                    </q.div>
                    <q.div h100 w100 frbr gap='10px'>
                        {renderButtons(3)}
                        {renderButtons(2)}
                        {renderButtons(1)}
                    </q.div>
                </q.div>

            </q.div>
        </ModalContainer>
    )
}
export default connect(ConfirmModal);