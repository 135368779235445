/** @jsx jsx */
import { jsx } from '@emotion/react'
jsx
import * as React from 'react'
import { connectedComponentHelper } from '../typedconnect'
import DateFormat from '../common/dateFormat'
import DynamicList from '../common/dynamicList'
import { sortDate } from '../../utils/dateHelper'
import { Avatar, IColumnOption } from '@wideassessment/waui'
import q from 'qjuul'
import * as _copy from 'copy-to-clipboard'

interface IOwnProps {
    searchText: string
}

const copy = (text: string) => {
    _copy(text)
    furball.info('Copied to clipboard')
}

const COLS = [  
    { key: 'companyName', title: 'Company', minWidth:'150px', sortable: true, formatFunc: (item) => <q.div gap="10px" frcl pa="0 15px"><Avatar size="35px" imgUrl={item.logo}/><p>{item.companyName}</p></q.div>,},
    { key: 'userName', title: 'User', sortable: true},
    { key: "email", title: "Email", sortable: true, flex: 3, formatFunc: (item) => <q.div pointer onClick={x => copy(item.email)}>{item.email}</q.div>},
    { key: "name", title: "Filter", sortable: true },
    { key: 'created', title: 'Created', width: '220px', formatFunc: (item) => (<DateFormat item={item.created}/>), sortable: true, sortFunc: (a, b) => sortDate(a, b, 'created')},
] as IColumnOption[]

const { propsGeneric, connect } = connectedComponentHelper<IOwnProps>()(x=> ({
    filters: x.filters
}), (_, x) => ({
    FetchFilters: x.filters.FetchFilters
}))

class CompanyList extends React.PureComponent<typeof propsGeneric>{
    FetchData = async (start, itemsPerPage, sortBy, descending ) => {
        await this.props.FetchFilters(start, itemsPerPage, sortBy, this.props.searchText, descending)
    }
    render(){
        return(
            <DynamicList data={this.props.filters} fetchData={this.FetchData} itemsPerPage={10} columns={COLS} defaultSort={'created'} typeId={2} searchText={this.props.searchText}/>
        )   
    }

}

export default connect(CompanyList)