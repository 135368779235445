
import { getType, ActionType } from 'typesafe-actions'
import { IApiListRequest, ICandidate, ICompany, ICompanyUser, IUserListItem } from '../types'
import * as actions from './actions'
export type ActionsTypes = ActionType<typeof actions>

export interface DashboardState {
    candidates?: IApiListRequest<ICandidate>
    companyUsers?: IApiListRequest<ICompanyUser>
    companies?: IApiListRequest<ICompany>
    leads?: IApiListRequest<ICompany>
    users?: IApiListRequest<IUserListItem>
}

const initdashboardState: DashboardState = {
    candidates: null,
    companyUsers: null,
    companies: null,
    users: null
}


const reducer = (state: DashboardState = initdashboardState, action: ActionsTypes) => {
    var newState = { ...state }
    if (action.type === getType(actions.set)) {
        return ({ ...state, [action.payload.key]: action.payload.data })
    }
    else if (action.type === getType(actions.remove)) {
        
        return ({ ...state, [action.payload.key]: { ...state[action.payload.key], items: (state[action.payload.key] as any).items.filter(x => x.id !== action.payload.id) } })
    }
    else if (action.type === getType(actions.setItemModal)) {
        const items: any[] = newState?.[action.payload.response.key]?.items
        if (!items) return newState

        return ({
            ...newState, [action.payload.response.key]: {
                ...newState[action.payload.response.key],
                items: items.map(x => {
                    if (x.id === action.payload.parentUserId) {
                        return { ...x, modalRequest: action.payload.response.data }
                    }
                    return x
                })

            }})
    }
    else if (action.type === getType(actions.startTrialSuccess)) {
        return ({
            ...state, companies: state.companies.items.map(c => {
                if (c.id === action.payload) return { ...c, status: 'Trial' }
                return c
            })
        })
    }

    return state
}

export default reducer