/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import { useEffect, useState } from 'react';
import { Button } from '@wideassessment/waui';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import q from 'qjuul';
import styled from '@emotion/styled';
import { useDropArea } from 'react-use';

export interface BlogPost {
    id: number,
    body: string,
    created: string,
    modified: string,
    subtitle: string,
    thumbnail: string,
    isPublished: boolean,
    title: string,
    author?: {
        name: string,
        avatar: string
    }
  
}

const cssContainer = css`
    width: 100%;
    height: 500px;
    background-size: cover;
    border: 3px solid #2ECC71;
    transition: transform 500ms ease-out;
    

`

function getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise<string>((resolve, reject)=> {

        reader.onload = function () {

            if(typeof reader.result === "string")
                resolve(reader.result)
        };

    })
   
}

const Input = styled('input')({
    display: 'none',
});
  
export default function BlogEditor({onImage, preview}: {onImage:any, preview: string}) {

    const [imagePreview, setImagePreview] = useState<string>()
    const [bond, dropAreaState] = useDropArea({
        onFiles: files => _onImage(files[0])
    });

    useEffect(()=> {
        setImagePreview(preview)
    }, [preview])

    function _onImage(img) {
        getBase64(img).then(base64img => setImagePreview(base64img))
        
        onImage && onImage(img)

    }
  
    const cssPreviewImage= css`
        background-image: url(${imagePreview && imagePreview});
    `

    const cssHoverWithFile= css`
    border: 3px solid lightgray;
    border-radius: 10px;
    transform: scale(1.1);

    `

    return (
 
        <q.div fccc css={[cssContainer, cssPreviewImage, dropAreaState.over && cssHoverWithFile]} {...bond}>
     
            <label htmlFor="contained-button-file">
                <Input accept="image/*" id="contained-button-file" onChange={(e) => _onImage(e.target.files[0]) } type="file" />
                <Button theme={'go'} padding="10px 15px" label="Drop or select cover image" />
            </label>
     
        </q.div>
     
    )
}