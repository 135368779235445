import { CompanyRequirementsState, ICompanyDefinedUserTag, IExperience, ILanguage, IPlan, IPrice, ISkill, IUserItem, UserSkillExperience } from "@wideassessment/waui"
import { IOwnProfile } from "./profile/reducer"

export interface IApiListRequest<T> {
    start: number
    end: number
    total: number
    searchText?: string
    sortBy?: string
    decending?: boolean
    items: Array<T>
}

export interface IUserListItem extends withModal<any> {
    id: string
    publicShareCode: string
    name: string
    email: string
    picture: string
    quality: string
    source: string
    county: string
    country: string
    created: string
    isFeatured: boolean
}

export interface IFeaturedListItem extends withModal<any> {
    id: string
    publicShareCode: string
    firstName: string
    lastName: string
    email: string
    picture: string
    area: string
    created: string
}

export interface ICompany extends withModal<any> {
    id: number,
    name: string,
    logo: string
    quality?: string, 
    registrationDate?: string
    sources?: Array<IRegistrationSource>
    status?: string
    useInvoice?: boolean
}
export interface ISavedFilter {
    id: number,
    name: string,
    logo: string,
    email: string,
    userName: string,
    companyName?: string, 
    createdDate?: string
}
export interface ICompanyUser extends withModal<any>{
    id: number,
    name: string,
    logo: string, 
    companyName: string,
    email:string,
    totalViews:number,
    picture: string,
    lastViewed:string,
    lastLogin:string

}
export interface ICandidate extends withModal<any>{
    id: string
    name: string
    picture?: string
    email: string
    totalViews:number,
    lastViewed:string,
    lastLogin:string
}
export interface IRegistrationSource
{
    source: UserSource
    note?: string
}
export enum UserSource
    {
    WaEmailsWithFreeTips,
    Media,
    SocialMedia,
    Blog,
    SomeoneToldMeAboutIt,
    Googled,
    Other
}

export enum BlogTypes
    {
    Both,
    Candidate,
    Company,
}
export interface withModal<T> {
    modalRequest?: IApiListRequest<T>
}

export interface IFirstLoad {
    user: IOwnProfile
    //activeCompanyUser: IApiCompanyRelation
    //termsOfUse: ITermsOfUseState
    //blacklistItems: Array<IBlacklist>
    //blacklistedCompanies: Array<String>
    //companies: Array<IApiCompanyRelation>
    //companyInvite: Array<ICompanyInvite>
    emailAdresses: { emails: Array<string>}
    experiences: Array<IExperience>
    files: Array<File>
    languages: Array<ILanguage>
    //listingApplications: Array<ListingApplication>
    pokedCompanies: Array<number>
    //shareApplicants: Array<ICompanyShareUser>
    skills: Array<ISkill>
    userItems: Array<IUserItem>
    userSkillExperiences: Array<UserSkillExperience>
    //userSkills: Array<IUserSkill>
    //employeeInvites: Array<CoworkerInvite>
    config: {cloudinary : string}
    globalTags: Array<ICompanyDefinedUserTag>
    //textTemplates: Array<ITemplate>
    requirements: Array<CompanyRequirementsState>
    //rewardData: RewardState['candidate']
    //savedFilters: Array<SavedFilter>
    prices: Array<IPrice>
    subscriptionsPlans: Array<IPlan>
    hasOpenedCandidate: boolean
    hasSaveCandidate: boolean
    hasViewedCandidate: boolean
    //rewards: ICompanyReward[]
    //rewardProgress: ICompanyRewardProgress[]
    //userKeyValues: IUserKeyValue[]
    //locations: Array<Country>
    emailOptOuts: Array<string>
    //statistics: Array<IStatistic>
}
  