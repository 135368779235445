/** @jsx jsx */
import { jsx, css } from '@emotion/react'
// tslint:disable-next-line:no-unused-expression
jsx
import * as React from 'react'
import { connectedComponentHelper } from '../../typedconnect'
import DateFormat from '../../common/dateFormat'
import DynamicList from '../../common/dynamicList'
import { sortDate } from '../../../utils/dateHelper'
import { Avatar, Ellipsis, IColumnOption } from '@wideassessment/waui'
import q from 'qjuul'
import { IApiListRequest, ICandidate } from '../../../state/types'
import * as _copy from 'copy-to-clipboard'

interface IOwnProps {
    searchText: string
    list?: IApiListRequest<ICandidate>
    id?: number
}

const copy = (text: string) => {
    _copy(text)
    furball.info('Copied to clipboard')
}

const { propsGeneric, connect } = connectedComponentHelper<IOwnProps>()(x => ({
    candidates: x.dashboard.candidates,
}), (_, x) => ({
    FetchActivity: x.dashboard.FetchActivity,
    closeDrawer: x.drawer.closeDrawer
}))

class CandidateList extends React.PureComponent<typeof propsGeneric>{
    fetchData = async (start, itemsPerPage, sortBy, descending) => {
        await this.props.FetchActivity(start, itemsPerPage, sortBy, this.props.searchText, descending, this.props.id, true)
    }
    createCols = (isSubList: boolean, closeDrawer) => {
        return [
            { key: 'name', minWidth: '150px', title: 'Name', sortable: true, formatFunc: (item) => <q.div gap="10px" frcc pa="0 15px"><Avatar size="35px" imgUrl={item.picture} /><Ellipsis tooltip={`${item?.isFeatured ? "🔥 " : ""}${item?.name}`}>{`${item?.isFeatured ? "🔥 " : ""}${item?.name}`}</Ellipsis></q.div>, },
            { key: "email", title: "Email", sortable: true, flex: 3, formatFunc: (item) => <q.div onClick={_=> {
                copy(item.email)
                setTimeout(() => {
                    closeDrawer()
                }, 1);
            }} pointer>{item.email}</q.div>},
            !isSubList && { key: 'lastLogin', title: 'Last login', width: '220px', formatFunc: (item) => (<DateFormat item={item.lastLogin} modal />), sortable: true, sortFunc: (a, b) => sortDate(a, b, 'lastLogin') },
            { key: 'lastViewed', title: 'Last viewed', width: '220px', formatFunc: (item) => (<DateFormat item={item.lastViewed} modal />), sortable: true, sortFunc: (a, b) => sortDate(a, b, 'lastViewed') },
            { key: 'totalViews', title: 'Total views', width: "100px", sortable: true, formatFunc: (item) => `#${item.totalViews}` }

        ] as IColumnOption[]
    }

    render() {
        const isSubList = typeof this.props.id !== 'undefined' && typeof this.props.id != null
        let data = this.props.candidates
        if (isSubList) data = this.props.list
        return (
            <DynamicList fetchData={this.fetchData} dashboardKey='candidates' data={data} itemsPerPage={10} columns={this.createCols(isSubList, this.props.closeDrawer)} onRowClick={!isSubList} defaultSort={isSubList ? 'lastViewed' : 'lastLogin'} typeId={1} searchText={this.props.searchText} />
        )
    }

}

export default connect(CandidateList)