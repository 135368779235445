import { Validators } from "@wideassessment/waui"
import axios from "axios"
import { ExtractableErrorMessages, GetErrorMsg } from 'utils/inputerrors'

export const toggleFeatured = async (username) => {
    try {
        await axios.post(`${process.env.BACKEND_URL}/api/admin/featureduser/${username}`)
        furball.info('User changed')
    } catch (error) {
        if (error.response && error.response.data && error.response.data.reason) {
            furball.error(error.response.data.reason)
        }
    }
}

export const removeApplicationConnection = async (companyId, applicationId) => {
    if (window.confirm('Are you sure')) {
        await axios.delete(`${process.env.BACKEND_URL}/api/admin/comany/applications/${companyId}/${applicationId}`)
    }
}

export const connectUserToCompany = async (username: string, companyId: number, type: string, note) => {
    try {
        await axios.post(`${process.env.BACKEND_URL}/api/admin/companies/${companyId}/add/${username}/${type.toLowerCase()}`, { note })
        furball.success('Added ' + username + ' as ' + type)
    } catch (e) {
        furball.error(e)
        console.log('e', e)
    }
}

export const removeCompanyUser = async (companyUserId) => {
    if (window.confirm('Are you sure')) {
        await axios.delete(`${process.env.BACKEND_URL}/api/admin/comany/user/${companyUserId}`)
        furball.info('Connection removed')
    }
}

export const resetPassword = async (username) => {
    try {
        await axios.get(`${process.env.BACKEND_URL}/api/admin/reset?username=${encodeURIComponent(username)}`)
        furball.info('Reset link sent')
    } catch (error) {
        furball.error('Something went wrong ?')
    }
}

export const changePassword = (username, password) => {
    return new Promise<void>(async (resolve, reject) => {
        var pwdErrors = Validators.password.filter(x => typeof x(password) != 'boolean')

        if (pwdErrors.length === 0) {
            try {
                await axios.post(`${process.env.BACKEND_URL}/api/admin/reset/password`, { username: username, password: password })
                furball.info('Password changed')
                resolve()
            } catch (error) {
                furball.error('Something went wrong ?')
                console.log(error)
                reject()
            }
        } else {
            pwdErrors.forEach((x) => furball.error(GetErrorMsg(x(password), ExtractableErrorMessages.password)))
            furball.error('Password does not meet requirements')
            reject()
        }
    })
}