/** @jsx jsx */
import { jsx, css } from '@emotion/react'
jsx
import * as React from 'react'
import q from 'qjuul'
import SwitchMenu from './../common/switchmenu'
import CompanyAdminList from './lists/companyUserList'
import CandidateList from './lists/candidateList'
import { Search, styles } from '@wideassessment/waui'
export interface IOwnProps {
    userName: string
    lang: string
}
interface IOwnState {
    switchMenu: number
    searchField: string
    bouncedSearchField?: string
}

const cssDashboard = css`
background-color:white;
border-radius:20px;
padding: 0 40px 40px;
`
class Dashboard extends React.PureComponent<IOwnProps, IOwnState>{
    constructor(props) {
        super(props)
        this.handleSwitch = this.handleSwitch.bind(this)
    }

    state: IOwnState = {
        switchMenu: 0,
        searchField: '',
        bouncedSearchField: ''
    }

    handleSwitch(id: number) {
        this.setState({ switchMenu: id })
    }

    renderSearch = () => {
        const cssSearch = css`
            width:fit-content;
        `

        return (
            <q.div frtl>
                <q.div css={cssSearch}>
                    <Search border value={this.state.searchField} onChange={(x) => this.setState({ searchField: x })} placeholder="Search" debounce={500} onChangeBounced={(x) => this.setState({ bouncedSearchField: x })} />
                </q.div>
            </q.div>
        )
    }

    renderActiveList = () => {
        if (this.state.switchMenu === 0) return <CompanyAdminList searchText={this.state.bouncedSearchField} />
        if (this.state.switchMenu === 1) return <CandidateList searchText={this.state.bouncedSearchField} />
    }
    render() {
        return (
            <q.div>
                <q.div css={cssDashboard}>
                    <q.div ma={'0 0 40px 0'}>
                        {this.renderSearch()}
                    </q.div>
                    <q.div frcl>
                        <SwitchMenu setActive={this.handleSwitch} active={this.state.switchMenu} views={[
                            {label: 'Admins'},
                            {label: 'Candidates'}
                        ]} />
                    </q.div>
                    <q.div ma="20px 0 0 0">
                        {this.renderActiveList()}
                    </q.div>
                </q.div>
            </q.div>
        )
    }
}

export default Dashboard