/** @jsx jsx */
import { jsx, css } from '@emotion/react'
// tslint:disable-next-line:no-unused-expression
jsx
import * as React from 'react'
import DynamicList from '../../common/dynamicList'
import axios from 'axios'
import { IColumnOption } from '@wideassessment/waui'

interface IOwnProps {
    id: string | number
}

const LogList = (props: IOwnProps) => {
    const [data, setData] = React.useState<any>()

    const fetchData = () => {
        let url = `${process.env.BACKEND_URL}/api/log/lookup?userId=${props.id}`
        axios.get<any[]>(url).then(x => {
            setData({
                items: [...x.data],
                total: x.data.length,
                start: 0,
                end: x.data.length
            })
        })
    }

    const COLS: IColumnOption[] = [
        { key: 'user', title: 'User' },
        { key: 'companyUser', title: 'Company user' },
        { key: 'company', title: 'Company' },
        { key: 'value', title: 'Value' },
        { key: 'type', title: 'Type' },
        { key: 'subType', title: 'Sub Type' },
        { key: 'date', title: 'Created' },

    ]
    React.useEffect(() => {
        fetchData()
    }, [])

    return (
        <DynamicList data={data} defaultSort={'Date'} columns={COLS} />
    )

}

export default LogList