import axios from 'axios'
import furball from 'wa-furball'
axios.defaults.withCredentials = true
import * as a from './actions'
import { Dispatch } from 'redux'
import { ICompany } from 'state/types'

export const FetchActivity = (dispatch: Dispatch, pageStart: number, pageEnd: number, sortBy: string, searchText: string, reverse: boolean, id? : string | number, isCandidate?: boolean,) => {
    let url = `${process.env.BACKEND_URL}/api/salad/dashboard/${ (typeof id === 'number' || isCandidate) ? 'candidates' : 'companyusers'}?pageStart=${pageStart}&pageEnd=${pageEnd}&sortBy=${sortBy}${searchText && searchText !== '' ? `&searchText=${searchText}`: ''}&reverse=${reverse}`
    if (id !== undefined && id !== null && typeof id === 'number') {
        url += `&companyUserId=${id}`
    } else if (id !== undefined && id !== null && typeof id === 'string') {
        url += `&candidateId=${id}`
    }

    return axios.get<a.IMultiplePayload>(url).then(x=> {

        const data = {...x.data, decending: reverse, sortBy: sortBy ? sortBy : null};
        if (id !== undefined && id !== null) {
            const mdata: a.IChildUpdatePayload = {
                parentUserId: id,
                response: { data, key: !isCandidate ? 'candidates' : 'companyUsers'}
            }
            dispatch(a.setItemModal(mdata))
        } else {
            dispatch(a.set({data, key: isCandidate ? 'candidates' : 'companyUsers'}))
        }
    }).catch(err => {
        console.log(err);
    })
}

export const fetchCompanyActivity = (dispatch: Dispatch, pageStart: number, pageEnd: number, sortBy: string, searchText: string, reverse: boolean, withSubscription: boolean) => {
    let url = `${process.env.BACKEND_URL}/api/salad/dashboard/companies?pageStart=${pageStart}&pageEnd=${pageEnd}&sortBy=${sortBy}${searchText && searchText !== '' ? `&searchText=${searchText}`: ''}&reverse=${reverse}&withSubscription=${withSubscription}`
    return axios.get(url).then(x=> {
        dispatch(a.set({data: {...x.data, decending:reverse}, key: 'companies'}))
    }).catch(err => {
        console.log(err);
    })
}

export const FetchUsers = (dispatch: Dispatch, pageStart: number, pageEnd: number, sortBy: string, searchText: string, reverse: boolean) => {
    const amount = pageEnd - pageStart
    let url = `${process.env.BACKEND_URL}/api/salad/users?start=${pageStart}&amount=${amount}&sortBy=${sortBy}${searchText && searchText !== '' ? `&search=${searchText}`: ''}&decending=${reverse}`
    return axios.get(url).then(x=> {
        dispatch(a.set({data: {...x.data, decending:reverse}, key: 'users'}))
    }).catch(err => {
        console.log(err);
    })
}

export const FetchFeatured = (dispatch: Dispatch, pageStart: number, pageEnd: number, sortBy: string, searchText: string, reverse: boolean) => {
  const amount = pageEnd - pageStart
  let url = `${process.env.BACKEND_URL}/api/salad/users/featured?start=${pageStart}&amount=${amount}&sortBy=${sortBy}${searchText && searchText !== '' ? `&search=${searchText}`: ''}&decending=${reverse}`
  return axios.get(url).then(x=> {
      dispatch(a.set({data: {...x.data, decending:reverse}, key: 'users'}))
  }).catch(err => {
      console.log(err);
  })
}

export const startTrial = (dispatch: Dispatch, id: number) => {
  return axios.post(`${process.env.BACKEND_URL}/api/salad/company/start-trial/${id}`).then(x=> {
    dispatch(a.startTrialSuccess(id))
  }).catch(err => {
    furball.error('error starting trial')
    console.log(err);
  })
}
export const extendTrial = (dispatch: Dispatch, id: number) => {
  return axios.post(`${process.env.BACKEND_URL}/api/salad/company/extend-trial/${id}`).then(x=> {
  }).catch(err => {
    furball.error('error extending trial')
    console.log(err);
  })
}
export const deleteCompany = (dispatch: Dispatch, id: number) => {
  return axios.delete(`${process.env.BACKEND_URL}/api/admin/comany/${id}`).then(x=> {
    dispatch(a.remove({id, key: 'companies'}))
  }).catch(err => {
    furball.error('error deleting company')
    console.log(err);
  })
}

export const deleteUser = (dispatch: Dispatch, id: string) => {
    return axios.delete(`${process.env.BACKEND_URL}/api/admin/user/${id}`).then(x=> {
        dispatch(a.remove({ id, key: 'users'}))
    }).catch(err => {
      furball.error('error deleting user')
        console.log(err);
    })
}