import * as React from 'react'
import { styled, Tooltip, TooltipProps } from '@mui/material'
import * as moment from "moment"
import q from "qjuul"
import { zIndex } from '../../utils/sizes'

export default function dateFormat (props: any, modal?: boolean){
    const OptionalVisibilityTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        zIndex: modal ? theme.zIndex.tooltip + zIndex.modal: theme.zIndex.tooltip,  
    }));
    return(
        <q.div>
            <OptionalVisibilityTooltip
                title={moment.utc(props.item).local().format('HH:mm')}>
                <q.div>
                    {props.item ? moment.utc(props.item).local().format('DD.MM.YYYY') : 'never'}
                </q.div>
            </OptionalVisibilityTooltip>
        </q.div>)}