/** @jsx jsx */
import { jsx } from '@emotion/react'
jsx
import * as React from 'react'
import DateFormat from 'app/components/common/dateFormat'
import DynamicList from 'app/components/common/dynamicList'
import { sortDate } from 'app/utils/dateHelper'
import { Avatar, Ellipsis, IColumnOption } from '@wideassessment/waui'
import q from 'qjuul'
import { connectHelper } from 'app/components/typedconnect'
import { IFeaturedListItem, IUserListItem } from 'app/state/types'
import * as _copy from 'copy-to-clipboard'
import _ from 'lodash'
import UserModel from 'app/components/listmodels/user'
import FeaturedButtonList from './users/buttons/featuredbuttonlist'

interface IOwnProps {
    searchText: string
}

const copy = (text: string) => {
    _copy(text)
    furball.info('Copied to clipboard')
}

const COLS = (closeDrawer) => [    
    { key: 'name', title: 'Name', flex: 2, sortable: true, formatFunc: (item: IFeaturedListItem) => <q.div gap="10px" frcc pa="0 15px"><Avatar size="35px" imgUrl={item.picture}/><Ellipsis tooltip={item.firstName + " " + item.lastName}>🔥{item.firstName} {item.lastName}</Ellipsis></q.div>,},
    { key: "email", title: "Email", sortable: true, flex: 3, formatFunc: (item) => <q.div pointer onClick={x => copy(item.email)}>{item.email}</q.div>},
    { key: "area", title: "Area", sortable: true },   
    { key: "created", title: "Feature date", sortable: true, formatFunc: (item) => new Date(item.created).toLocaleDateString() },
    { key: "featureCampaign", title: "Campaign Date", sortable: true, formatFunc: (item) => {if (isNaN(new Date(item.featureCampaign).getTime())) {
        let now = new Date();
        let nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);  // First day of the next month
        let dayOfWeek = nextMonth.getDay(); // 0 (Sunday) to 6 (Saturday)
        let firstWednesday = new Date(nextMonth);

        // Calculate the first Wednesday of the next month
        firstWednesday.setDate(nextMonth.getDate() + (3 - dayOfWeek + 7) % 7); 

        return <q.div style={{fontStyle: "italic"}}>{firstWednesday.toLocaleDateString()}</q.div>;
    } else {
        return new Date(item.featureCampaign).toLocaleDateString()} 
    }
},   
    { key: "actions", flex: 2, noClick: true, title: "Actions", float: 'right', sortable: false, formatFunc: (item) => <FeaturedButtonList user={item}/> },
] as IColumnOption[]

const { connect, propsGeneric } = connectHelper<IOwnProps>()(x=> ({
    users: x.dashboard.users,
}), (_, x) => ({
    FetchFeatured: x.dashboard.FetchFeatured,
    closeDrawer: x.drawer.closeDrawer,
}))

const UserList = (props: typeof propsGeneric) =>{
    
    const FetchData = (start, itemsPerPage, sortBy, descending ) =>  props.FetchFeatured(start, itemsPerPage, sortBy, props.searchText, descending)

    const renderRowModel = (itemId: string) => <UserModel userId={itemId} />
    return(
        <DynamicList drawerModel={renderRowModel} data={props.users} fetchData={FetchData} itemsPerPage={10} columns={COLS(props.closeDrawer)} defaultSort={'created'} typeId={2} searchText={props.searchText}/>
    )   

}

export default connect(UserList)