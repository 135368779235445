import * as a from './actions';
import { Dispatch } from 'redux';
import { Popup } from './reducer';


export const addPopup = (dispatch: Dispatch, popup: Popup | Popup['element']) => {
    var _popup = (popup as any)?.element ? popup as Popup : { element: popup } as Popup;
    dispatch(a.addPopup(_popup));
};
export const updatePopup = (dispatch: Dispatch, popup: Popup) => {
    dispatch(a.updatePopup(popup));
}

export const removePopup = (dispatch: Dispatch) => {
    dispatch(a.closePopup());
};