import axios from 'axios'
axios.defaults.withCredentials = true
import * as uiActions from '../ui/actions'
import { Dispatch } from 'redux'

export const login = async (dispatch: Dispatch, email, password) => {
    try {
        await axios.post(process.env.BACKEND_URL + '/api/login', { username: email, password })
        dispatch(uiActions.login(true))
    } catch (error) {
        console.log(error)
    }
}

export const logout = (dispatch: Dispatch) => {
    return new Promise<boolean>((resolve, reject) => {
        axios.get<any>(process.env.BACKEND_URL + '/api/logout').then(x => {
            dispatch(uiActions.logout())
            resolve(true)
        }).catch(x => {
            furball.error('error logging out')
            reject(false)
        })
    })
}