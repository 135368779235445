/** @jsx jsx */
import { jsx, css } from '@emotion/react'
jsx
import { useEffect } from 'react'
import qs from 'query-string'
import { connectHelper } from '../typedconnect'

const autoPopups = [
    //{
    //    key: 'subscribe_cancel',
    //    component: <SubscribeCancel/>
    //},
    //{
    //    key: 'subscribe_success',
    //    component: <SubscribeSuccess/>
    //},
]

const { connect, propsGeneric} = connectHelper()(x=> ({
    popup: x.popup.activePopup,
}), (_, x) => ({
    addPopup: x.popup.addPopup,
    redirect: x.ui.redirect,
}))

const AutoPopper = (props: typeof propsGeneric) => {
    useEffect(() => {
        const params = qs.parse(location.search)
        if (!params.popup || props.popup) return;
        const popup = autoPopups.find(x => params.popup === x.key)
        if (!popup) return;
        props.addPopup(popup.component)
        delete params.popup
        const newUrl = qs.stringifyUrl({ url: `${location.pathname}`, query: params })
        props.redirect(newUrl)
    }, [])

    return null
}

export default connect(AutoPopper)