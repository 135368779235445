import * as React from 'react'
import { ErrorBoundary } from '@wideassessment/waui'
import EmptyPage from './app/components/common/contentempty'
import App from './app/components/App'
import { applyMiddleware, combineReducers, createStore } from 'redux'
import { createRoot } from 'react-dom/client'
import { composeWithDevTools } from '@redux-devtools/extension'
import { Provider } from 'react-redux'
import LoginChecker from './app/components/login/loginchecker'
import ui from './app/state/ui/reducer'
import dashboard from './app/state/dashboard/reducer'
import drawer from './app/state/drawer/reducer'
import profile from './app/state/profile/reducer'
import filters from './app/state/filters/reducer'
import price from './app/state/price/reducer'
import popup from './app/state/popup/reducer'
import './styles/global.css'
import axios from 'axios'
import { createReduxHistoryContext } from 'redux-first-history'
import { createBrowserHistory } from 'history'
import _furball from 'wa-furball'

const { createReduxHistory, routerMiddleware } = createReduxHistoryContext({
    history: createBrowserHistory(),
});

const combinedReducer = combineReducers<any>({
    ui,
    dashboard,
    drawer,
    profile,
    filters,
    popup,
    price
})

declare global {
    interface Window {
        loadHubSpot: any,
        removeHubSpot: any
    }
    var furball: typeof _furball
}

globalThis.furball = _furball;

const store = createStore(combinedReducer, composeWithDevTools(applyMiddleware(routerMiddleware)))
export const history = createReduxHistory(store);

axios.interceptors.response.use((response) => {
    return response
}, (error) => {
    const toRef = `${process.env.BACKEND_URL}/login?goto=${location.href}`;
    if (location.href.includes(toRef)) return;
    if (error.response && error.response.status && error.response.status === 401) {
        window.location.href = toRef;
    }
    return Promise.reject(error)
})

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <Provider store={store}>
        <ErrorBoundary
            errorUI={
                <EmptyPage
                    label="Something went oh so wrong, oooooh so wrong! (Index)"
                    fullPage
                />
            }
        >
            <LoginChecker>
                <App userName={'Tester'} lang={'Typescript'} />
            </LoginChecker>
        </ErrorBoundary>
    </Provider>)
