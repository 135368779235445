import { Reducer } from 'redux'
import { getType, ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { IPrice } from '@wideassessment/waui'
export type UiActionsType = ActionType<typeof actions>

export type PriceState = {
    prices?: IPrice[]
}

const reducer: Reducer<PriceState> = (state: PriceState = {}, action: UiActionsType) => {
    switch (action.type) {
        case getType(actions.setPrices):
            return ({ ...state, prices: action.payload })
        default:
            return state
    }
}

export default reducer