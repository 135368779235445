import * as React from 'react';
import { useEffect } from 'react';
import { Modify } from '@wideassessment/waui';
import { Popup } from 'state/popup/reducer';
import { connectHelper } from 'app/components/typedconnect';

interface IOwnProps extends Modify<Popup, { element?: React.ReactNode}> {
    children: React.ReactNode;
    exit?: any
}

const { propsGeneric, connect } = connectHelper<IOwnProps>()(x=> ({
    activePopup: x.popup.activePopup
}), (_, x) => ({
    removePopup: x.popup.removePopup,
    updatePopup: x.popup.updatePopup
}))

const ModalContainer = (props : typeof propsGeneric) => {
    const [mounted, setMounted] = React.useState(false);

    useEffect(() => {
        const localProps : typeof propsGeneric= {
            ...props,
            ...props.activePopup,
            activePopup: undefined,
            exit: undefined,
            removePopup: undefined,
            updatePopup: undefined,
            children: undefined
        }
        setMounted(true);
        props.updatePopup(localProps as Popup);
    }, []);

    useEffect(() => {
        const localProps : typeof propsGeneric= {
            ...props,
            ...props.activePopup,
            activePopup: undefined,
            exit: undefined,
            removePopup: undefined,
            updatePopup: undefined,
            children: undefined
        }
        setMounted(true);
        props.updatePopup(localProps as Popup);
    }, [props.activePopup.id]);

    useEffect(() => {
        if (mounted) {
            props.removePopup();
        }
    }, [props.exit]);

    return props.children;
}

export default connect(ModalContainer);
