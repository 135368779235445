import axios from 'axios'
axios.defaults.withCredentials = true
import * as uiActions from '../ui/actions'
import * as priceActions from '../price/actions'
import * as profileAction from '../profile/actions'
import { push, goBack } from 'redux-first-history'
import { Dispatch } from 'redux'
import { IFirstLoad } from 'state/types'

export const redirect = (dispatch: Dispatch, path) => dispatch(push(path))
export const redirectBack = (dispatch: Dispatch) => dispatch(goBack())

export const checkIfLoggedIn = (dispatch: Dispatch) => {
    return new Promise((resolve, reject) =>{
        axios.get<IFirstLoad>(process.env.BACKEND_URL + '/api/login?basic=true&asCompany=true').then(r=> {
            dispatch(profileAction.set(r.data?.user))
            dispatch(priceActions.setPrices(r.data.prices))
            dispatch(uiActions.login(true))
            resolve(true)
        }).catch(error => {
            console.error(error);
            dispatch(uiActions.login(false))
            reject(false)
        })
    })
}
