/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import {TextField, Select, MenuItem, InputLabel} from '@mui/material';
import { useEffect, useState } from 'react';
import { Button } from '@wideassessment/waui'
import SunEditor from 'suneditor-react';
import './suneditor-contents.css';
import ImageUploader from './imageUploader'
import q from "qjuul"
import axios from 'axios';
import { BlogTypes } from '../../../state/types';
import { useDebounce } from 'react-use';
axios.defaults.withCredentials = true
const axiosMe = axios.create({ withCredentials: false })

export interface BlogPost {
    id: number,
    body: string,
    created: string,
    modified: string,
    subtitle: string,
    thumbnail: string,
    isPublished: boolean,
    title: string,
    url: string,
    thumbnailAlt: string,
    language: string,
    connectGuid: string,
    type: BlogTypes
    author?: {
        name: string,
        avatar: string
    }
  
}
const languages =[
    "no", "en"
]

const cssContainer = css`
    width: 100%;
    margin-top: 50px;
    padding: 50px;
    margin: 50px;
    border-radius: 15px;
    display: flex;
    background-color:white;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    width:700px;

`

interface IOwnProps{
    onChange?: Function
    onChangeDebounce?: Function
    onSave: Function
    onClose: Function
    debounceMs?: number
    data: Readonly<BlogPost>
    allPosts: Array<BlogPost>
}

const sunEditorOptions = {
    "buttonList": 
		[[
		    "undo",
		    "redo",
		    "image",
		    "fontSize",
		    "blockquote",
		    "bold",
		    "underline",
		    "italic",
		    "strike",
		    "fontColor",
		    "list",
		    "textStyle",
		    "removeFormat",
		    "codeView"
		]],

    stickyToolbar: "100px",
    minHeight: "800px",
    defaultStyle: "font-size:22px"

}

export const uploadCloudinaryImage = (data) => {

    const form = new FormData()
    form.append('file', data)
    form.append('upload_preset', 'profile')

    return new Promise<string>((resolve, reject)=>{

        axiosMe.post<any>('https://api.cloudinary.com/v1_1/wadev/upload', form).then(r => {
            resolve(r.data.secure_url)
        }).catch(e=> {
            console.log(e)
            reject(e)})
    }) 
} 

export default function BlogEditor({data, onChange, onSave, onClose, onChangeDebounce, allPosts, debounceMs = 2000}: IOwnProps) {
    const [formData, setFormData] = useState<BlogPost>()
    const [, _] = useDebounce(
        () => {
            onChangeDebounce && onChangeDebounce(formData)
        },
        debounceMs,
        [formData]
    );
  
    // When form data changes trigger onChange callback
    useEffect(()=>{
        onChange && formData && onChange(formData);
    }, [formData])

    useEffect(()=>{
        if(!data.language) setFormData({...data, language:'no'})
        else setFormData(data)
    }, [data])
    
    function onImageUpload (img) {
        uploadCloudinaryImage(img).then(imgUrl=> {
            setFormData((prevData)=> ({...prevData, thumbnail:imgUrl.substring(0, imgUrl.lastIndexOf('.')) + '.webp'}))
        } )

    }
    function createUrlFromText (text) {
        return text.replace(' ', '-')  
    }
    if(!formData) return null; 
  
    const saveBlogPost = () => {
        let data = formData
        if(!data.url || !data.url.trim()) data.url = createUrlFromText(data.title)
        onSave(data)
    }
    const previewImgPlaceholder = "https://res.cloudinary.com/wadev/image/upload/v1639059657/profile/waedit_ncmowh.webp";
    return (
        <div css={cssContainer}>
     
            <TextField fullWidth autoFocus onChange={(e)=>setFormData({...formData, title: e.target.value})} value={formData.title} id="title" label="Title" />
            <TextField fullWidth autoFocus onChange={(e)=>setFormData({...formData, url: e.target.value})} value={formData.url} id="URL" label="URL" placeholder={createUrlFromText(formData.title)} />
            <TextField fullWidth onChange={(e)=>setFormData({...formData, subtitle: e.target.value})} value={formData.subtitle} multiline minRows="5" id="subtitle" label="Subtitle" variant="outlined" />
            <q.div frcc>
                <q.div>
                    <InputLabel id="languageLabel">Language</InputLabel>
                    <Select labelId="languageLabel" id="language" value={formData.language} onChange={(e) => setFormData({...formData, language: e.target.value})} sx={{width:"200px", marginRight:"10px"}}>
                        {languages.map(l => {
                            return (<MenuItem key={l} value={l}>{l}</MenuItem>)
                        })}
                    </Select>
                </q.div>
                <q.div>
                    <InputLabel id="typeLabel">Type</InputLabel>
                    <Select id="type" labelId="typeLabel" value={formData.type} onChange={(e) => setFormData({...formData, type: e.target.value as BlogTypes})} sx={{width:"200px", marginRight:"10px"}}> 
                        {(Object.keys(BlogTypes)).filter((x : any )=> !isNaN(x)).map((l, key) => {
                            return(<MenuItem key={l} value={l}>{BlogTypes[l]}</MenuItem>)
                        })
                        }
                    </Select>
                </q.div>
                <q.div>
                    <InputLabel id="connectLabel">Connect</InputLabel>
                    <Select id="connectGuid" labelId="connectLabel" value={formData.connectGuid} onChange={(e) => setFormData({...formData, connectGuid: e.target.value})} sx={{width:"200px"}}> 
                        <MenuItem key="noConnection" value={formData.connectGuid}>No</MenuItem>
                        {allPosts.filter((x : any )=> x.id !== formData.id).map((l, key) => {
                            return(<MenuItem key={l.id} value={l.connectGuid}>{l.title}</MenuItem>)
                        })
                        }
                    </Select>
                </q.div>
            </q.div>
            <ImageUploader preview={formData.thumbnail || previewImgPlaceholder} onImage={(img)=> onImageUpload(img)}/>
            <TextField fullWidth onChange={(e)=>setFormData({...formData, thumbnailAlt: e.target.value})} value={formData.thumbnailAlt} id="thumbnailAlt" label="Alt tag" variant="outlined" />

            <SunEditor height="auto" setOptions={sunEditorOptions} setContents={formData.body} onChange={(content)=>setFormData((prevData)=> ({...prevData, body: content}))}></SunEditor>

            <q.div frtl >
                <Button theme={'text'} handleClick={()=> onClose && onClose()} padding="10px 15px" label="Cancel" />
                <Button theme={'go'} handleClick={()=> saveBlogPost()} padding="10px 15px" label="Save and publish changes" />
            </q.div>
     
        </div>
     
    )
}