import * as a from './actions'
import {DrawerState} from './reducer'
import { Dispatch } from 'redux'

export const openDrawer = (dispatch: Dispatch, content: any, contentId: any, conentType: string | number ) => {
    const state : Partial<DrawerState> = {
        content: content,
        contentId: contentId, 
        contentType: conentType,
        open: true, 
    }
    dispatch(a.setDrawer(state))
}
export const closeDrawer = (dispatch: Dispatch ) => {
    dispatch(a.closeDrawer())
}

export const setSize = (dispatch: Dispatch, width, height) => {
    dispatch(a.setSize({
        width: width,
        height:height
    }))
}
