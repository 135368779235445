
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
// tslint:disable-next-line:no-unused-expression
import { NavLink } from "react-router-dom";
import * as React from 'react'
import q from 'qjuul'
import {styles, Ninyo, Button, Icons, Avatar, Dropdown, ItemGroup } from '@wideassessment/waui'

interface IOwnProps {

}
interface IOwnState {
    scrollPos: number
}
export class Header extends React.Component<IOwnProps, IOwnState> {
    state: IOwnState = {
        scrollPos: 0
    }
    renderSystemTitle = () => {
        //Should be connected to redux when we get further into the system
        return(
            <q.div frcc he="100%" ma="0 0 0 30px">
                <q.p css={css`font-family: bree,sans-serif;`} foSi="21px">{`Support`}</q.p>
                <q.div ma="0 0 0 35px " wi="1px" he="80%" baCo={styles.color.white}/>
            </q.div>)
    }
    setScrollPos = () => {
        const getScroll = function() {
            if (window.pageYOffset != undefined) {
                return [pageXOffset, pageYOffset];
            } else {
                var sx, sy, d = document,
                    r = d.documentElement,
                    b = d.body;
                sx = r.scrollLeft || b.scrollLeft || 0;
                sy = r.scrollTop || b.scrollTop || 0;
                return [sx, sy];
            }
        }
        this.setState({scrollPos: getScroll()[1]})

    }
    componentDidMount = () => {
        document.addEventListener('scroll', this.setScrollPos)
    }
    componentWillUnmount = () => {
        document.removeEventListener('scroll', this.setScrollPos)
    }

    renderLogo = () => {
        const alignLogo = css`
            transform: translateY(-${this.state.scrollPos}px);
        `

        return (
            <q.div css={alignLogo} fccc wi="100px" co={styles.color.white}>

                <q.div>
                    <Ninyo size="50px" color={styles.color.mineShaft} />
                </q.div>

            </q.div>
        )
    }
    renderPageButtons = () => {
        const style = {
            borderBottom: 'solid 4px transparent',
            marginTop: "5px",
        }
        const activeStyle = {
            borderBottom: 'solid 4px white',
            marginTop: "5px",
        }
        const height = "80px"

        return (
            <React.Fragment>
                <NavLink to="/" style={({ isActive }) => isActive ? activeStyle : style}>
                    <Button height={`${height}`} key={"Users"} theme={'dark'} label={"Users"}/>
                </NavLink>
                <NavLink to="/activity" style={({ isActive }) => isActive ? activeStyle : style}>
                    <Button height={`${height}`} key={"User activity"} theme={'dark'} label={"Activity"}/>
                </NavLink>
                <NavLink to="/campaigns" style={({ isActive }) => isActive ? activeStyle : style}>
                    <Button height={`${height}`} key={"Filters"} theme={'dark'} label={"Campaigns"}/>
                </NavLink>
                <NavLink to="/blog" style={({ isActive }) => isActive ? activeStyle : style}>
                    <Button height={`${height}`} key={"Blog"} theme={'dark'} label={"Blog"}/>
                </NavLink>
            </React.Fragment>
        )
    }

    renderGeneralButtons = () => {
        const cssForcePointer = css`* {cursor: pointer !important;}`
        const adminIcon = (<q.div co={styles.color.white} ma="2px 0 0 0" pa="3.5px 7px" baCo={styles.color.black} frcc boRa="10px" foSi="12px">ADMIN</q.div>)

        const menuData : Array<ItemGroup>= [
            {
                title: 'General',
                showTitle: false,
                items: [ {
                    label: 'Back to WA app',
                    onClick: ()=> {},
                }, {
                    label: 'Sign out',
                    onClick: ()=> {},
                }]
            },
            {
                title: 'WAdmin pages',
                showTitle: true,
                items: [ {
                    label: 'Sales',
                    onClick: ()=> {},
                    icon: adminIcon
                }, {
                    label: 'Stats',
                    onClick: ()=> {},
                }, {
                    label: 'Support',
                    onClick: ()=> {},
                    active: true,
                    icon: adminIcon
                }]
            },
        ]

        const renderAvatarButton = (
            <q.div ma="0 50px" >
                <Dropdown.Menu items={menuData}>
                    <q.div frcl pointer>
                        <q.div ma="0 10px 0 0">Stine</q.div>
                        <q.div css={cssForcePointer}><Avatar size={`40px`}/></q.div>
                    </q.div>
                </Dropdown.Menu>
            </q.div>
        )

        const notifyData : Array<ItemGroup>= [
            {
                title: 'Notifications',
                showTitle: false,
                items: [ {
                    label: 'New company',
                    icon: <Icons.IconFilled color={styles.color.regentGrey}/>
                },
                {
                    label: 'New candidate',
                    icon: <Icons.IconFilled color={styles.color.regentGrey}/>
                },
                {
                    label: 'User locked out',
                    icon: <Icons.IconWarining color={styles.color.broom}/>
                }, {
                    label: 'Ping test failed',
                    onClick: ()=> {},
                    icon: <Icons.IconError color={styles.color.radicalRed}/>
                }]
            }
        ]

        return(
            <q.div frcc>
                <q.div po="relative" z="3000">
                    {renderAvatarButton}
                </q.div>
            </q.div>
        )
    }
    renderMenuSection = () => {
        return (
            <q.div f1 boRa="0 0 0 35px" he="100%" baCo={styles.color.mineShaft}>
                <q.div he="100%" frcl co={`white`}>
                    {this.renderSystemTitle()}
                    <q.div f1 frcl he="100%">{this.renderPageButtons()}</q.div>
                    {this.renderGeneralButtons()}
                </q.div>
            </q.div>
        )
    }
    render() {
        return (
            <q.div frcl w100 he="90px">
                {this.renderLogo()}
                {this.renderMenuSection()}
            </q.div>
        );
    }
}

export default Header