/** @jsx jsx */

import { css, jsx } from '@emotion/react';
import q from 'qjuul'
import { useState } from 'react';
import CompanyList from '../dashboard/lists/companyList';
import UserList from '../dashboard/lists/users';
import { Search, styles } from '@wideassessment/waui'
import SwitchMenu from '../common/switchmenu';

export default function Users() {
    const cssDashboard = css`
    border-radius:20px;
    padding: 40px;
    background-color:white;
    `
    const cssStyle = css`
          margin:  0 0 40px 0px;
          padding: 0px;
          font-family: 'bree';
          color: ${styles.color.white}; 
          h1 {
            margin: 0px;
          }
        `
    const [searchField, setSearchField] = useState('');
    const [bouncedSearchField, setBouncedSearchField] = useState('');
    const [page, setPage] = useState(0)

    return (
        <q.div>
            <q.div css={cssDashboard}>
                <q.div frtl>
                    <q.div css={css`width: fit-content; margin: 0 0 20px;`}>
                        <Search border value={searchField} onChange={setSearchField} placeholder="Search" debounce={500} onChangeBounced={setBouncedSearchField} />
                    </q.div>
                </q.div>
                <SwitchMenu setActive={setPage} active={page} views={[
                    { label: 'Companies' },
                    { label: 'Users' },
                    { label: 'Leads & Customers'}
                ]} />
                {
                    page === 0 ?
                        <CompanyList searchText={bouncedSearchField} /> : page === 1 ?
                        <UserList searchText={bouncedSearchField} /> :
                        <CompanyList searchText={bouncedSearchField} withSubscription/>
                }
            </q.div>
        </q.div>
    )
}