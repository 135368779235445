/** @jsx jsx */
import { jsx } from '@emotion/react'
jsx
import ModalContainer from '../modalcontainer'
import q from 'qjuul'
import { connectHelper } from 'app/components/typedconnect'
import { IUser } from 'app/components/hooks/useUserData'
import { Button, styles } from '@wideassessment/waui'
import { useState } from 'react'
import ConfirmAction from '../confirm/confirmaction'
import api from 'app/components/api'
import { TextField } from '@mui/material'

interface IOwnProps {
    user: IUser
}

const { propsGeneric, connect } = connectHelper<IOwnProps>()(null, (_, x) => ({
    addPopup: x.popup.addPopup,
    removePopup: x.popup.removePopup
}))

const ChangePasswordModal = (props: typeof propsGeneric) => {
    const [password, setPassword] = useState('')

    const handleReset = async () => {
        props.removePopup()
        props.addPopup(<ConfirmAction             
            message={`Reset password for ${props.user.name}(${props.user.username})`} 
            onConfirm={()=> api.user.resetPassword(props.user.username)} 
        />)
    }
    
    return (
        <ModalContainer width='500px' padding='50px 40px' >
            <q.div f1 fctl w100 maxw="500px">
                <q.h2 ma="0 0 10px 0">Change password</q.h2>
                <q.p foSi="11px" foIt>Password should be atleast 8 characters long, contain big and small letters, and have number in it</q.p>
                <q.div w100 ma="10px 0 0" frtl gap="10px">
                    <TextField 
                        fullWidth
                        label='New password'
                        type='password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <q.div foSi="14px">
                        <Button height="56px" theme='go' label='Save changes' handleClick={() => {
                            api.user.changePassword(props.user.username, password).then(() => props.removePopup())
                        }} />
                    </q.div>                    
                </q.div>    
                <q.p ma="10px 0 0" onClick={handleReset} unLi co={styles.color.royalBlue} pointer foSi="11px" >Send reset link instead</q.p>          
            </q.div>
        </ModalContainer>
    )
}

export default connect(ChangePasswordModal)