import { getType, ActionType } from 'typesafe-actions'
import * as actions from './actions'
export type ActionsTypes = ActionType<typeof actions>

export interface DrawerState {
    open?: boolean
    content?: any
    contentId?: string | number
    contentType?: string | number
    size: size
}
export interface size {
    width: number
    height: number
}

const initState : DrawerState = {
    size: {
        width: 0,
        height: 0
    }
}

const reducer = (state: DrawerState = initState, action: ActionsTypes) => {
    if (action.type === getType(actions.setDrawer)) {
        return {...state, ...action.payload}
    }
    else if(action.type === getType(actions.closeDrawer)) {
        return {...state, open: false}
    }
    else if (action.type === getType(actions.setSize)) {
        const newData = action.payload
        return {...state, size: newData}
    } 
    else {
        return state
    }
}

export default reducer