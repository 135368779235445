/** @jsx jsx */
import { jsx } from '@emotion/react'
jsx
import * as React from 'react'
import {useState} from 'react'
import { connectedComponentHelper } from '../../typedconnect'
import DateFormat from '../../common/dateFormat'
import DynamicList from '../../common/dynamicList'
import { sortDate } from '../../../utils/dateHelper'
import { Avatar, Ellipsis, IColumnOption } from '@wideassessment/waui'
import q from 'qjuul'
import CompanyButtonList from './companyButtonList'
import CompanyModel from '../../listmodels/company'

interface IOwnProps {
    searchText: string
    withSubscription?: boolean
}

const { propsGeneric, connect } = connectedComponentHelper<IOwnProps>()(x=> ({
    companies: x.dashboard.companies,
}), (_, x)=> ({
    fetchCompanyActivity: x.dashboard.fetchCompanyActivity
}))

const COLS = [      
    { key: 'name', title: 'Company', minWidth: '150px', sortable: true, formatFunc: (item) => <q.div gap="10px" frcc pa="0 15px"><Avatar size="35px" imgUrl={item.logo}/><Ellipsis tooltip={`${item?.isFeatured ? "🔥 " : ""}${item?.name}`} >{`${item?.isFeatured ? "🔥 " : ""}${item?.name}`}</Ellipsis></q.div>,},
    { key: 'registrationDate', title: 'Registration', width: '220px', formatFunc: (item) => (<DateFormat item={item.registrationDate}/>), sortable: true, sortFunc: (a, b) => sortDate(a, b, 'registrationDate')},
    { key: "quality", title: "Quality", sortable: true },
    { key: "status", title: "Status", sortable: true, formatFunc: (item) => `${item.status == undefined ? "No Plan" : item.status}` },
    { key: "useInvoice", title:"Payment", sortable: true,  formatFunc: (item) => item.useInvoice == null ? '' : item.useInvoice ? 'Invoice' : 'Credit card'},
    { key: "actions", float: 'right', sortable: false, title: "Actions", formatFunc: (item) => <CompanyButtonList company={item} /> },
] as IColumnOption[]

class CompanyList extends React.PureComponent<typeof propsGeneric>{

    FetchData = async (start, itemsPerPage, sortBy, descending ) => {
        await this.props.fetchCompanyActivity(start, itemsPerPage, sortBy, this.props.searchText, descending, this.props.withSubscription)
    }
    
    render() {        
        const renderRowModel = (itemId: number) => <CompanyModel companyId={itemId} />

        return(
            <DynamicList data={this.props.companies} onRowClick drawerModel={renderRowModel} fetchData={this.FetchData} itemsPerPage={10} columns={COLS} defaultSort={'registrationDate'} typeId={2} searchText={this.props.searchText}/>
        )   
    }

}

export default connect(CompanyList)