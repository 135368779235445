/** @jsx jsx */
import { jsx } from '@emotion/react'
// tslint:disable-next-line:no-unused-expression
jsx
import { connectedComponentHelper } from 'app/components/typedconnect'
import { css } from '@emotion/react'
import * as React from 'react'
import q from 'qjuul'
import qs from 'query-string'
import { Betterblur, Icons, ZIndex, styles } from '@wideassessment/waui'
import AutoPopper from './autopopper'
import ResizeDetector from 'react-resize-detector'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router'

const { propsGeneric, connect } = connectedComponentHelper()(x => ({
    activePopup: x.popup.activePopup,
}), (_, x) => ({
    removePopup: x.popup.removePopup,
    redirect: x.ui.redirect
}))

const CustomPopup = (props: typeof propsGeneric) => {
    const [_width, setWidth] = useState(0)
    const [_height, setHeight] = useState(0)
    const [isShowing, setIsShowing] = useState(false)
    const loc = useLocation()

    const handleExit = () => {
        const { onClose = () => { }, noExit } = props.activePopup || {}
        if (!noExit) {
            onClose();
            setIsShowing(false)
            props.removePopup()
        }
    }

    const renderExitButton = () => {
        const { exitButton, exitButtonSize, caretForExit } = props.activePopup || {}
        if (!exitButton) return null;
        return (
            <q.div po="absolute" top='30px' ri="30px" z1 co="black">
                {
                    caretForExit ? (
                        <q.div pointer onClick={handleExit} >
                            <Icons.IconCaret size={exitButtonSize} color={props.activePopup?.exitButtonColor ?? styles.color.white} />
                        </q.div>
                    ) : (
                        <Icons.IconCloseBig pointer size={exitButtonSize} color={props.activePopup?.exitButtonColor ?? styles.color.white} handleClick={handleExit} />
                    )
                }
            </q.div>
        )
    }

    const scrollHideControl = () => {
        const { element } = props.activePopup || {}
        const notShowing = !element || !props.activePopup || !React.isValidElement(element)
        const isFullscreen = (
            props.activePopup?.width === "100%" &&
      props.activePopup?.height === "100%" &&
      ((!props.activePopup?.maxWidth &&
        !props.activePopup?.maxHeight) || props.activePopup?.maxWidth === "100%" && props.activePopup?.maxHeight === "100%")
        ) || (_width >= window.innerWidth && _height >= window.innerHeight)

        const opacityOnResetOfPage = props.activePopup?.overlayOpacity != 0
        document.body.parentElement.style.overflow = notShowing || !(isFullscreen || opacityOnResetOfPage) ? 'auto' : "hidden"
    }

    useEffect(() => {
        const querys = qs.parse(loc?.search)
        var updated = false
        if (props.activePopup?.withHistory) {
            if (props.activePopup && (!querys.pop || querys.pop != props.activePopup?.id)) {
                updated = true
                const query = qs.stringify({ ...querys, pop: props.activePopup.id });
                if (query != qs.stringify(querys) && !isShowing) {
                    props.redirect(`?${query}`);
                    setIsShowing(true)
                }
                else {
                    if (!props.activePopup || !querys.pop && isShowing) {
                        delete querys.pop
                        const query = qs.stringify({ ...querys });
                        if (query != qs.stringify(querys)) props.redirect(`?${query}`);
                        if (!querys.pop) {
                            setIsShowing(false)
                            handleExit()
                        }
                    }
                }
            }
        }
        if (!querys.popup && !updated && isShowing && (!props.activePopup || (props.activePopup?.withHistory && !querys.pop))) {
            delete querys.pop
            const query = qs.stringify({ ...querys });
            if (query != qs.stringify(querys)) props.redirect(`?${query}`);
            if (!querys.pop) {
                setIsShowing(false)
                handleExit()
            }
        }
        scrollHideControl()
    })

    useEffect(() => {
        if (props.activePopup?.withHistory) {
            const querys = qs.parse(loc?.search)
            if (props.activePopup && (!querys.pop || querys.pop != props.activePopup?.id)) {
                const query = qs.stringify({ ...querys, pop: props.activePopup.id });
                if (query != qs.stringify(querys)) {
                    props.redirect(`?${query}`);
                    setIsShowing(true)
                }
            }
        }
        scrollHideControl()
        return () => {
            document.body.parentElement.style.overflow = 'auto'
            const querys = qs.parse(loc?.search)
            if (querys.pop) {
                delete querys.pop
                props.redirect(qs.stringify({ ...querys }))
            }
        }
    }, [])

    const {
        width = "unset",
        maxWidth = "unset",
        height = "unset",
        maxHeight = "unset",
        padding = "10px",
        containerPadding = "10px",
        backgroundColor = styles.color.white,
        element,
        minHeight,
        margin,
        position = "center-center",
        textColor = styles.color.black,
    } = props.activePopup || {}

    const flexProp = { [`fr${position.split('-').map(x => x[0].toLowerCase()).join('')}`]: true }

    const PopupElement = () => {
        if (!element || !props.activePopup) return null;
        if (!React.isValidElement(element)) return null;

        const cssContainer = css`
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      background-color: rgba(0,0,0,${props.activePopup?.overlayOpacity ?? 0.5});
      z-index: ${props.activePopup?.zIndex ?? ZIndex.actionConfirmations + 500};
      padding: ${containerPadding};
      font-weight: unset;
      font-size: unset;
    `
        return (
            <q.div css={cssContainer} {...flexProp} id="customPopup">
                <ResizeDetector handleWidth handleHeight onResize={(width, height) => { setWidth(width); setHeight(height) }} />
                <Betterblur onBlur={handleExit}>
                    <q.div baCo={backgroundColor} ma={margin} he={`${height}`} maxh={maxHeight} wi={`${width}`} pa={padding} co={textColor} minh={minHeight} maxw={maxWidth} fctc po="relative">
                        {renderExitButton()}
                        {element}
                    </q.div>
                </Betterblur>
            </q.div>
        )
    }

    return (
        <React.Fragment>
            <AutoPopper />
            {PopupElement()}
        </React.Fragment>
    )
}

export default connect(CustomPopup)
