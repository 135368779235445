/** @jsx jsx */
import { jsx } from '@emotion/react'
jsx
import * as React from 'react'
import { connectHelper } from '../../typedconnect'
import { Button } from '@wideassessment/waui'
import { ICompany } from 'app/state/types'
import q from 'qjuul'

interface IOwnProps {
    company: ICompany
}

const { propsGeneric, connect } = connectHelper<IOwnProps>()(null, (_, x) => ({
    startTrial: x.dashboard.startTrial,
    extendTrial: x.dashboard.extendTrial,
    deleteCompany: x.dashboard.deleteCompany,    
}))

class CompanyList extends React.PureComponent<typeof propsGeneric>{
    onStartTrial = async () => {
        const result = await confirm("Are you sure you want to start trial for " + this.props.company.name + "?")
        if (result) {
            this.props.startTrial(this.props.company.id)
            return;
        }
    };
    onExtendTrial = async () => {
        const result = await confirm("Are you sure you want to extend trial for " + this.props.company.name + "?")
        if (result) {
            this.props.extendTrial(this.props.company.id)
            return;
        }
    };
    onDelete = async () => {
        const result = confirm("Are you sure you want to delete " + this.props.company.name + "?")
        if (result) {
            this.props.deleteCompany(this.props.company.id)
            return;
        }
    };

    renderTrialButton(){
        if(this.props.company.status === 'Legacy' || !this.props.company.status){
            return(
                <Button minWidth='0px' label='Start trial' handleClick={this.onStartTrial} theme={'text'} />
            )  
        } else if(this.props.company.status === 'Trial'){
            return(
                <Button minWidth='0px' label='Extend trial' handleClick={ this.onExtendTrial} theme={'text'} />
            )  
        }
        return null
    }
    render(){
        return(
            <q.div w100 frcr gap="15px">
                <Button minWidth='0px' label='Delete' handleClick={this.onDelete} theme={'text'} />
                {this.renderTrialButton()}
            </q.div>
        )   
    }

}

export default connect(CompanyList)