/** @jsx jsx */
import { css, jsx } from '@emotion/react';
import q from 'qjuul'
import { useState } from 'react';
import BlogPostInfo from './blogpostinfo';
import BlogPostControlPanel from './blogpostcontrolpanel';
import { BlogPost } from '../blogpage';

interface IOwnProps {
    p: BlogPost
    highlight?: boolean
    onSwitchPostState: Function
    onEditPost: Function
}

export default function BlogPostListItem({p, highlight=false, onSwitchPostState, onEditPost}: IOwnProps) {
    const [isHovering, setIsHovering] = useState(false) // use a hook instead 

    const cssBlogPostItem = css`
        padding: 15px 25px;
        background-size: cover;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-position: left;
        position: relative;
        background-color: white;
        min-height: 50px;
        border: 1px solid #e3e3e67b;
        border-left: 5px solid ${p.isPublished ? "#37db97" : "#db3752"};
        transition: height 5s linear;
    `

    const cssHighlight= css`
        border: 10px solid black;
    `

    return (
        <div onMouseEnter={()=>setIsHovering(true)} onMouseLeave={()=>setIsHovering(false)} css={[cssBlogPostItem, highlight && cssHighlight]} key={p.id}>
            <q.div fctl fsb maxh="250px" w100>
                <BlogPostInfo post={p} buttons={<BlogPostControlPanel hide={!isHovering} onEditPost={onEditPost} onSwitchPostState={onSwitchPostState} post={p}/>}/>    
            </q.div>
        </div>
    )
}