
import { getType, ActionType } from 'typesafe-actions'
import { IApiListRequest, ISavedFilter} from '../types'
import * as actions from './actions'
export type ActionsTypes = ActionType<typeof actions>

const reducer = (state: IApiListRequest<ISavedFilter> = null, action: ActionsTypes) => {
    if (action.type === getType(actions.setFilters)) {
        return action.payload
    } 
    else {
        return state
    }
  
}

export default reducer