/** @jsx jsx */
import { jsx, css } from '@emotion/react'
jsx
import { useEffect, useState } from 'react'
import ModalContainer from '../modalcontainer'
import { Button, Debounce, ICompany, styles } from '@wideassessment/waui'
import q from 'qjuul'
import { connectHelper } from 'app/components/typedconnect'
import { IUser } from 'app/components/hooks/useUserData'
import { Autocomplete, MenuItem, Select, TextField } from '@mui/material'
import api from 'app/components/api'
import { EConnectionType } from 'app/components/dashboard/lists/connections'

interface IOwnProps {
    user: IUser
    refresh: () => void
}

const { propsGeneric, connect } = connectHelper<IOwnProps>()(null, (_, x) => ({
    removePopup: x.popup.removePopup
}))

const ConnectUserModal = (props: typeof propsGeneric) => {

    const types = Object.values(EConnectionType).filter(x => typeof x === 'string').map(key => ({ key, label: key, value: key }))
    const [search, setSearch] = useState<string>('')
    const [options, setOptions] = useState([])

    const [selectedType, setSelectedType] = useState<string>(types[2].label as string)
    const [selectedCompany, setSelectedCompany] = useState<ICompany>()
    const [note, setNote] = useState('')

    const searchCompanies = async () => {
        const res = await api.search.companies(search)
        setOptions(res.data?.items || [])
    }

    useEffect(() => {
        searchCompanies()
    }, [])

    const handleConnect = async () => {
        await api.user.connectUserToCompany(props.user.username, selectedCompany.id, selectedType, note)
        props.removePopup()
        props.refresh()
    }

    return (
        <ModalContainer width='600px' padding='0px' >
            <q.div f1 fctl w100>
                <q.div f1 frtl fsa w100 pa="40px 0 20px">
                    <q.div w100 maxw="200px">
                        <q.p bold>Company</q.p>
                        <Debounce value={search} amount={500} output={searchCompanies} />
                        <Autocomplete
                            fullWidth
                            disablePortal
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onChange={(e, x) => typeof x === 'object' && setSelectedCompany(x)}
                            options={options.map(x => ({ ...x, id: x.id, title: x.name }))}
                            getOptionLabel={(option) => option?.title ?? ''}
                            onInputChange={(event, newInputValue) => { setSearch(newInputValue) }}
                            filterOptions={(options, params) => {
                                const filtered = options.filter(x => x.title.toLowerCase().includes(params.inputValue.toLowerCase()));
                                return filtered
                            }}
                            style={{ width: '100%' }}
                            renderInput={(params: any) => <TextField {...params} placeholder="Select a company" />}
                        />
                    </q.div>
                    <q.div w100 maxw="200px">
                        <q.p bold>Connection type</q.p>
                        <Select MenuProps={{ disablePortal: true }} fullWidth placeholder='Select type' value={selectedType} onChange={x => setSelectedType(x.target.value)}>
                            {types.map(x => <MenuItem key={x.key} value={x.value}>{x.label}</MenuItem>)}
                        </Select>
                    </q.div>
                </q.div>

                {
                    selectedType === 'Applicant' && (
                        <q.div fccc w100>
                            <q.div pa="0 20px 20px 20px" w100 maxw="500px">
                                <q.p bold>Comment</q.p>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={2}
                                    placeholder="Add comment to notify company"
                                    value={note}
                                    onChange={e => setNote(e.target.value)}
                                />
                            </q.div>
                        </q.div>
                    )
                }
                <q.div f1 frcr w100>
                    <q.div frcl pa="0 30px">
                        <Button label='cancel' theme='text' style='smallsquare' handleClick={props.removePopup} />
                        <Button label='Connect' theme='go' style='smallsquare' margin='20px' handleClick={handleConnect} />
                    </q.div>

                </q.div>
            </q.div>
        </ModalContainer>
    )
}

export default connect(ConnectUserModal)