
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
// tslint:disable-next-line:no-unused-expression
jsx
import * as React from 'react'
import {
    BrowserRouter as Router,
    Route,
    Routes,
} from "react-router-dom";
import Dashboard from './dashboard'
import Header from './header'
import Blog from './blog/blogpage'
import q from 'qjuul'
import Layout from './layout'
import { styles } from '@wideassessment/waui'
import Users from './users'
import Filters from './campaigns'

export interface IOwnProps {
    userName: string
    lang: string
}

interface IOwnState {
    showModal:boolean
    modalId:number
    switchMenu:number
}
const withLayout = (child) => {
    return(
        <Layout header={<Header/>} footer={<q.div w100 frcc baCo={styles.color.go} co={styles.color.white}><q.p>Footer, will probably remove this, but adding now for support atleast</q.p></q.div>}>
            {child}
        </Layout>
    )
}
class App extends React.PureComponent<IOwnProps, IOwnState>{
    constructor(props){
        super(props)
    }

    render(){
        return(
            <Router>
                <Routes>
                    <Route path="/blog" element={withLayout(<Blog/>)}>
                    </Route>
                    <Route path="/activity" element={withLayout( <Dashboard userName = {this.props.userName} lang = {this.props.lang}/>)}>
                    </Route>
                    <Route path='/' element={withLayout(<Users />)} />
                    <Route path='/campaigns' element={withLayout(<Filters />)} />
                </Routes>
            </Router>
        )
    }
}

export default App