/** @jsx jsx */
import { jsx } from "@emotion/react";
jsx;
import { connectHelper } from "../../../../typedconnect";
import { Button } from "@wideassessment/waui";
import q from "qjuul";
import { IFeaturedListItem, IUserListItem } from "app/state/types";
import api from "../../../../api";
import useUserData from "app/components/hooks/useUserData";
import * as _copy from "copy-to-clipboard";

interface IOwnProps {
  user: IUserListItem;
}

const { propsGeneric, connect } = connectHelper<IOwnProps>()(null, (_, x) => ({
  deleteUser: x.dashboard.deleteUser,
}));

const UserButtonList = (props: typeof propsGeneric) => {
  const onDelete = async () => {
    const result = confirm(
      "Are you sure you want to delete " + props.user.name + "?"
    );
    if (result) {
      props.deleteUser(props.user.id);
      return;
    }
  };
  return (
    <q.div frcr w100>
        <Button
            label="Delete"
            minWidth="0px"
            handleClick={onDelete}
            theme={"text"}
        />
    </q.div>
  );
};

export default connect(UserButtonList);
