import { Avatar, Button, Ellipsis, IColumnOption } from '@wideassessment/waui';
import api from 'app/components/api';
import DynamicList from 'app/components/common/dynamicList'
import moment from 'moment';
import q from 'qjuul';
import React from 'react'

export enum EConnectionType {
    Coworker,
    Admin,
    Applicant,
}

export interface IConnection {
    id: number;
    companyId: number;
    userId: string;
    
    companyName: string;
    companyPicture: string;

    userName: string;
    userPicture: string;

    created: string;
    type: keyof typeof EConnectionType;
}

interface IOwnProps {
    items: IConnection[]
    refresh: () => void
    type: 'user' | 'company'
}

export const Connections = (props: IOwnProps) => {

    const renderActionBar = (rowId) => {
        const handleDisconnect = async () => {
            const row = props.items.find(c => c.id == rowId)
            if (!row) return
            if (row.type === 'Admin' || row.type === 'Coworker') await api.user.removeCompanyUser(rowId)
            else if (row.type === 'Applicant') await api.user.removeApplicationConnection(row.companyId, rowId)
            props.refresh()
        }

        return (
            <q.div frcr gap="20px">
                <Button label='Disconnect' handleClick={handleDisconnect} theme='text' minWidth='0' padding='0' height='35px' />
            </q.div>
        )
    }    

    const typeNames = {
        Coworker: 'Coworker',
        Admin: 'Admin',
        Applicant: 'User to company',
    }
    const showCompany = props.type === 'user'

    const connectionColumns = [
        {
            key: 'companyName', title: 'Connections', sortable: false, flex: 5,
            formatFunc: (item: IConnection) => (
                <q.div frcr gap="10px">
                    <Avatar size="30px" imgUrl={showCompany ? item.companyPicture : item.userPicture} />
                    <Ellipsis>{showCompany ? item.companyName : item.userName}</Ellipsis>
                </q.div>
            )
        },
        { key: 'created', title: 'Date', sortable: false, formatFunc: (item) => moment.utc(item.created).local().format('DD.MM.YYYY') },
        { key: 'type', title: 'Connection', sortable: false, formatFunc: (item) => typeNames[item.type] },
        { key: 'actions', title: 'Actions', sortable: false, formatFunc: (item) => renderActionBar(item.id) },
    ] as IColumnOption[]

    const formattedConnectionData = {
        total: props.items?.length,
        start: 0,
        end: props.items?.length,
        sortBy: "created",
        searchText: "",
        decending: false,
        items: props.items,
    }

    return (
        <DynamicList data={formattedConnectionData} columns={connectionColumns} defaultSort={'created'} itemsPerPage={10} />
    )
}
