import { jsx } from '@emotion/react'
import { ErrorMessages, InputTypes } from '@wideassessment/waui'
import { isNumber } from 'lodash'

export interface IErrorMessage {
    type: ErrorMessages,
    message: string
}

interface IExtractableErrorMessage {
    [type: InputTypes | string]: IErrorMessage[]
}

export interface IErrorList {
    type: InputTypes | string
    enumCodes: number[]
    errorMessages?: IErrorMessage[]
}

const zipErrorMessages: IErrorMessage[] = [
    {
        type: ErrorMessages.Default,
        message: 'Missing fields',
    },
    {
        type: ErrorMessages.Format,
        message: "ZIP can only contain numbers"
    },
    {
        type: ErrorMessages.TooLong,
        message: "ZIP is too long"
    },
    {
        type: ErrorMessages.TooShort,
        message: "ZIP is too short"
    }
]

const emailErrorMessages: IErrorMessage[] = [
    {
        type: ErrorMessages.Email,
        message: "Email error"
    }
]

const passwordErrorMessages: IErrorMessage[] = [
    {
        type: ErrorMessages.Default,
        message: "Missing fields"
    },
    {
        type: ErrorMessages.TooShort,
        message: "Password must be at least 8 characters long."
    },
    {
        type: ErrorMessages.Password,
        message: "Password must contain at least one digit, lower and upper case letter."
    }
]

export const dateErrorMessages: IErrorMessage[] = [
    {
        type: ErrorMessages.Default,
        message: "Missing fields",
    },
    {
        type: ErrorMessages.Date,
        message: "Invalid input"
    }
]

export const defaultErrorMessages: IErrorMessage[] = [
    {
        type: ErrorMessages.Default,
        message: `Missing fields`
    }
]

export const ExtractableErrorMessages: IExtractableErrorMessage = {
    password: passwordErrorMessages,
    zip: zipErrorMessages,
    email: emailErrorMessages,
    date: dateErrorMessages
}

export const AllExtractableErrorMessages: IErrorMessage[] = [
    ...passwordErrorMessages,
    ...zipErrorMessages,
    ...emailErrorMessages,
    ...dateErrorMessages
]
/**
 * Takes enums and an object which contains the error messages for the enum.
 * @param {ErrorMessages} err - The given enum.
 * @param {IErrorMessage[]} errorList - List of messages containing the enum.
 * @returns {string} - The message to display.
 */
export const GetErrorMsg = (err: ErrorMessages | boolean, errorList?: IErrorMessage[]) => {
    const errorMessages : IErrorMessage[] = errorList || (Object.keys(ErrorMessages).map(key => !isNumber(key) ? ErrorMessages[key] : parseInt(ErrorMessages[key])))
    return errorMessages.find(x=> x.type == err).message
}

export const ErrorToMsg = (error: ErrorMessages | number) => {
    return GetErrorMsg(error, AllExtractableErrorMessages)
}

interface IDebouncedFurballMessage {
    message: string,
    timeout: NodeJS.Timeout

}

let debouncedFurballMessages: IDebouncedFurballMessage[] = []
/** Debounces furball messages. */
const debounceFurballMessage = (message: string) => {
    const dbfm = debouncedFurballMessages.find(el => el.message === message)
    if (dbfm) {
        clearTimeout(dbfm.timeout)
        debouncedFurballMessages = debouncedFurballMessages.filter(el => el !== dbfm)
    }
    debouncedFurballMessages.push({ message: message, timeout: setTimeout(() => furball.error(message), 100) })
}

export const findErrorMessages = (enumCodes: number[], errorMessages: IErrorMessage[]): IErrorMessage[] => {
    return enumCodes.length !== 0
        && enumCodes.map(enumCode => errorMessages.find(errorMessage => errorMessage.type === enumCode))
}
/**
 * Finds the list of messages for the given error type.
 * @param {string} type - The type of error to get the messages for.
 * @param {IErrorMessage[]} errorMessages - The optionally specified messages to get the messages from.
 * @return The list of messages for the given type.
 */
const findExtractableErrorMessages = (type: string, errorMessages?: IErrorMessage[]): IErrorMessage[] => {
    let errorType: IErrorMessage[] = ExtractableErrorMessages[type]
    if (!errorType) errorType = errorMessages || defaultErrorMessages
    return errorType
}
/**
 * Finds error messages for the given enum.
 * @param {IErrorList} error - The list of errors to find messages from.
 * @return messages if there are any else false
 */
const handleEnumCodes = (error: IErrorList): IErrorMessage[] => {
    const extractableErrorMessages = findExtractableErrorMessages(error.type, error.errorMessages)
    return findErrorMessages(error.enumCodes, extractableErrorMessages)
}
/**
 * Renders furball messages from list of error messages.
 * @param {IErrorMessage[]} errorMessages - List of error messages.
 * @render furball
 */
const renderFurballMessages = (errorMessages: IErrorMessage[]) => {
    if(!errorMessages) return
    const missingFieldMessage = errorMessages.find(error => error?.type === ErrorMessages.Default)
    if (missingFieldMessage)
        errorMessages = [missingFieldMessage]
    errorMessages.forEach(error => debounceFurballMessage(error?.message))
}
/**
 * Takes list with optional errortype and displays error messages.
 * @param {IErrorList[]} errorList - List of errorTypes with enumCodes.
 * @param {string} type - When printing furball for that errortype only.
 * @returns True if there are errors.
 */
export const handleErrorMessages = (errorList: IErrorList[], type?: string): boolean => {
    const errorMessages = type
        ? handleEnumCodes(errorList.find(error => error.type === type) || { enumCodes: [], type: type })
        : errorList.map(error => handleEnumCodes(error))
            .filter(e => e)
            .flat()
    renderFurballMessages(errorMessages)
    return errorMessages.length !== 0
}
/**
 * Updates a list of error messages with respect to its type and optionally the errorMessages.
 * @param {number[]} enumCodes - List of enums.
 * @param {InputTypes} errorType - Type of ExtractableErrormessages.
 * @param {IErrorList[]} errorList - List of errorTypes with enumCodes.
 * @param {IErrorMessage[]} errorMessages - Defined error messages when using custom type.
 */
export const updateErrorList = (enumCodes: number[], errorType: InputTypes | string, errorList: IErrorList[], errorMessages?: IErrorMessage[]) => {
    const error = errorList.find(error => error.type === errorType)
    if (error) {
        error.enumCodes = enumCodes
    } else {
        errorList.push({ type: errorType, enumCodes: enumCodes, errorMessages: errorMessages })
    }
}