import { UiState } from './ui/reducer'
import { IOwnProfile } from './profile/reducer'
import { DashboardState } from './dashboard/reducer'
import { DrawerState } from './drawer/reducer'
import { IApiListRequest, ISavedFilter } from './types'
import { RouterState } from 'redux-first-history'
import { Dispatch, AnyAction } from 'redux'
import { IPopupState } from './popup/reducer'

import * as dashboard from './dashboard/creators'
import * as drawer from './drawer/creators'
import * as profile from './profile/creators'
import * as ui from './ui/creators'
import * as filters from './filters/creators'
import * as popup from './popup/creators'
import * as price from './price/creators'
import { PriceState } from './price/reducer'

export default interface IState {
    readonly profile: IOwnProfile,
    readonly ui: UiState,
    readonly router: RouterState,
    readonly dashboard: DashboardState
    readonly drawer: DrawerState
    readonly filters: IApiListRequest<ISavedFilter>
    readonly popup: IPopupState
    readonly price: PriceState
}

type ForceFirstNoAny<T> = {
    [creator in keyof T]: {
        [expo in keyof T[creator]]: ForceFuncNotAnyFirstArg<T[creator][expo]>
    }
}

type ForceFirstDispatch<T> = {
    [creator in keyof T]: {
        [expo in keyof T[creator]]: ForceDispatchFirstArg<T[creator][expo]>
    }
}

type IsExactlyAny<T> = boolean extends (T extends never ? true : false) ? true : false;
type ForceFuncNotAnyFirstArg<T> = T extends (...args: infer U) => any ? IsExactlyAny<U[0]> extends true ? never : T : never
type ForceDispatchFirstArg<T> = T extends (...args: infer U) => any ? U[0] extends Dispatch ? T : never : never

export const combindedCreators = {
    dashboard,
    drawer,
    profile,
    ui,
    filters,
    popup,
    price,
}

////
// If line below gives error, then there is a creator that have 'any' on first argument
combindedCreators satisfies ForceFirstDispatch<ForceFirstNoAny<typeof combindedCreators>>
// 'any' on first argument is not allowed, because must be able to detect if its the dispatch function
// This way we can ensure a format, and support better typeconnect features
////

export type TCombinedCreators = typeof combindedCreators

export type UnionToType<U extends Record<string, unknown>> = { [K in (U extends unknown ? keyof U : never)]: U extends unknown ? K extends keyof U ? U[K] : never : never }

export type RemoveFirstArg<T extends (...args: any) => any> = T extends (
    ignored: infer _,
    ...args: infer P
) => any ? P : any

export type RemoveDispatchType<T> = {
    [K in keyof T]: T[K] extends (...args) => any ? //if is function
    // if is dipatch function
        Parameters<T[K]>[0] extends Dispatch<AnyAction> ?
        //return type
            (...args: RemoveFirstArg<T[K]>) => ReturnType<T[K]>
            : T[K]
        : never
}

export type AllTCombinedCreators = RemoveDispatchType<UnionToType<TCombinedCreators[keyof TCombinedCreators]>>